import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SharpPhoneIcon from "icons/SharpPhoneIcon";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import useSWR, { mutate } from "swr";

import { SiteEditProps } from "./SiteEditPage";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import LightTooltip from "components/DalmatianDesignComponents/LightTooltip";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import { PERMISSION_NAME } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { ObjectPermissions, PermissionValues } from "services/ContentServer/Audit/serviceTypes/ObjectPermissions";
import { User } from "services/ContentServer/Identity";
import { UsersSWRKeys } from "services/ContentServer/Identity/constants";
import { MapSWRKeys, SingleSite, SitesDict } from "services/ContentServer/Map";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import {
  initialSiteState,
  orderedCountryRegionData,
  SiteActionType,
  siteReducer,
} from "views/Sites/SiteManagement/siteReducer";
import SiteReducerFilter from "views/Sites/SiteManagement/SiteReducerFilter";

const SiteInfoTab = ({
  siteEditProps,
  setSiteEditProps,
  setProcessing,
  setProcessingMsg,
}: {
  siteEditProps?: SiteEditProps;
  setSiteEditProps?: (value: SiteEditProps | undefined) => void;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessingMsg: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();
  const { contentServer } = useContext(RequestContext);
  const { users, featureAccess, profiles } = useUsers();
  const snackbar = useSnackbar();
  const [keyContact, setKeyContact] = useState<User | undefined>(undefined);
  const [showBtns, setShowBtns] = useState<boolean>(false);
  const { data: objPerms } = useSWR([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS], () =>
    contentServer.objectPermissionService.list([["content_type", "site"]])
  );

  const { data: fullUser } = useSWR(keyContact?.id ? [UsersSWRKeys.USERS, keyContact.id] : null, () =>
    keyContact?.id ? contentServer.identityService.getFullUser(keyContact.id) : null
  );

  const site = useMemo(() => {
    if (siteEditProps) {
      return siteEditProps.site;
    }
    return undefined;
  }, [siteEditProps]);

  const [siteState, dispatchSiteState] = useReducer(siteReducer, initialSiteState);

  const hasName = useMemo(() => siteState && siteState.name !== "", [siteState]);
  const hasContact = useMemo(() => siteState && siteState.keyContact && siteState.keyContact.data !== "", [siteState]);

  useEffect(() => {
    if (site !== undefined) {
      dispatchSiteState({
        type: SiteActionType.RESET_ALL,
        site: site,
        keyContact: {
          data: site?.keyContactObj?.id || "",
          displayVal: site?.keyContactObj?.displayName || "",
        },
      });
    }
  }, [site]);

  const modifyAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER].read;
  }, [featureAccess]);

  const contactUser = useMemo(() => {
    return Object.values(users).find((user) => user.userProfile.id === siteState.keyContact.data);
  }, [siteState.keyContact.data, users]);

  useEffect(() => {
    let active = true;
    const getKeyContact = async () => {
      if (contactUser) {
        try {
          setKeyContact(undefined);
          const user = await contentServer.identityService.getFullUser(contactUser.id);
          if (active) {
            setKeyContact(user);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    getKeyContact();
    return () => {
      active = false;
    };
  }, [contactUser, contentServer.identityService]);

  const regionOptions = useMemo(() => {
    return (
      Object.values(orderedCountryRegionData)
        .find((country) => country.countryName === siteState.country.displayVal)
        ?.regions.map((region) => {
          return { data: region.name, displayVal: region.name };
        }) || [{ data: "", displayVal: "No Regions Found" }]
    );
  }, [siteState.country]);

  const userOptions = useMemo(() => {
    return Object.values(users)
      .filter((user) => user.isActive)
      .map((user) => {
        return {
          data: user.userProfile.id,
          displayVal: user.userProfile.displayName,
        };
      });
  }, [users]);

  useEffect(() => {
    if (!regionOptions.find((region) => region.data === siteState.region.data)) {
      dispatchSiteState({
        region: { data: regionOptions[0].data, displayVal: regionOptions[0].data },
        type: SiteActionType.UPDATE_REGION,
      });
    }
  }, [regionOptions, siteState.region]);

  const handleUpdateSite = async () => {
    if (!site) {
      console.error("Site is undefined.");
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error saving site changes.",
      });
      return;
    }
    if (!modifyAccess) {
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "You do not have permission to modify these fields.",
      });
      return;
    }

    if (hasName && hasContact) {
      setProcessing(true);

      try {
        setProcessingMsg("Updating site...");
        if (siteState.keyContact.data === site?.keyContact) {
          const perm = objPerms?.find((perm) => perm.username === contactUser?.id && perm.objectId === site.id);
          const updatedPerm = new ObjectPermissions({
            ...perm,
            permissions: {
              add: PermissionValues.hasPermission,
              change: PermissionValues.hasPermission,
              delete: PermissionValues.hasPermission,
              view: PermissionValues.hasPermission,
            },
          } as ObjectPermissions);

          mutate(
            [ObjectPerissionSWRKeys.OBJECT_PERMISSIONS],
            (prevPerms: ObjectPermissions[]) => {
              const currPerms = [...prevPerms];
              currPerms.map((p) => {
                return p.id === updatedPerm.id ? { ...updatedPerm } : p;
              });
            },
            false
          );
          await contentServer.objectPermissionService.patch(updatedPerm.id || "", updatedPerm);
        }

        const updatedSite: SingleSite = {
          id: site.id,
          name: siteState.name,
          company: siteState.company,
          address: siteState.address,
          country: siteState.country.data,
          region: siteState.region.data,
          zipPostalCode: siteState.zipPostalCode,
          keyContact: siteState.keyContact.data,
          keyContactObj: profiles[siteState.keyContact.data],
          active: site.active,
          createdBy: site.createdBy,
          createdDate: site.createdDate,
          users: site.users,
        };

        mutate(
          MapSWRKeys.SITES,
          (prevSites: SitesDict) => {
            prevSites[site.id] = updatedSite;
            return prevSites;
          },
          false
        );
        await contentServer.mapService.updateSite(site.id, siteState);
        if (setSiteEditProps) {
          setSiteEditProps({
            site: {
              id: site.id,
              name: siteState.name,
              keyContact: siteState.keyContact.data,
              users: site.users,
              address: siteState.address,
              country: siteState.country.data,
              region: siteState.region.data,
              zipPostalCode: siteState.zipPostalCode,
              company: siteState.company,
              keyContactObj: profiles[siteState.keyContact.data],
            } as SingleSite,
          });
        }
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Successfully saved site changes.",
        });
      } catch (error) {
        console.error(error);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error saving site changes.",
        });
        await mutate(MapSWRKeys.SITES);
        await mutate([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS]);
      }

      setProcessing(false);
      setProcessingMsg("");
    }
    setShowBtns(false);
  };

  const handleCancel = useCallback(() => {
    dispatchSiteState({
      site: site,
      keyContact: {
        data: site?.keyContactObj?.id || "",
        displayVal: site?.keyContactObj?.displayName || "",
      },
      type: SiteActionType.RESET_ALL,
    });
    setShowBtns(false);
  }, [site]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          width: "100%",
          height: "67vh",
          padding: "24px 0px 0px 24px",
          overflowY: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: 0,
            gap: "8px",
            width: "50%",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1">Site Name</Typography>
            <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
              *
            </Typography>
          </Box>
          <FixedHeightTextField
            variant="outlined"
            required={true}
            placeholder={"New site name"}
            value={siteState.name || ""}
            sx={{ width: "100%" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatchSiteState({ name: event.target.value, type: SiteActionType.UPDATE_NAME });
              setShowBtns(true);
            }}
            disabled={!modifyAccess}
          />
          <RequiredFieldMsg style={{ display: !hasName ? "" : "none" }} />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: 0,
            gap: "8px",
            width: "50%",
          }}
        >
          <Typography variant="subtitle1">Address</Typography>
          <FixedHeightTextField
            variant="outlined"
            value={siteState.address}
            sx={{ width: "100%" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatchSiteState({ address: event.target.value as string, type: SiteActionType.UPDATE_ADDRESS });
              setShowBtns(true);
            }}
            disabled={!modifyAccess}
          />
        </Box>
        <Box style={{ display: "flex", alignItems: "flex-start", padding: 0, gap: "40px", width: "50%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: 0,
              gap: "8px",
              width: "100%",
            }}
            onClick={() => {
              setShowBtns(true);
            }}
          >
            <Typography variant="subtitle1">Country</Typography>
            {modifyAccess ? (
              <SiteReducerFilter
                options={orderedCountryRegionData.map((c) => {
                  return { data: c.countryName, displayVal: c.countryName };
                })}
                siteState={siteState}
                stateKey={"country"}
                dispatchSiteState={dispatchSiteState}
                action={SiteActionType.UPDATE_COUNTRY}
              />
            ) : (
              <Typography variant="body1">{siteState.country.displayVal}</Typography>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: 0,
              gap: "8px",
              width: "100%",
            }}
            onClick={() => {
              setShowBtns(true);
            }}
          >
            <Typography variant="subtitle1">Region</Typography>
            {modifyAccess ? (
              <SiteReducerFilter
                options={regionOptions}
                siteState={siteState}
                stateKey={"region"}
                dispatchSiteState={dispatchSiteState}
                action={SiteActionType.UPDATE_REGION}
              />
            ) : (
              <Typography variant="body1">{siteState.region.displayVal}</Typography>
            )}
          </Box>
        </Box>
        <Box style={{ display: "flex", alignItems: "flex-start", padding: 0, gap: "40px", width: "50%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: 0,
              gap: "8px",
              width: "100%",
            }}
            onClick={() => {
              setShowBtns(true);
            }}
          >
            <Typography variant="subtitle1">Zip/Postal Code</Typography>
            <FixedHeightTextField
              variant="outlined"
              value={siteState.zipPostalCode}
              sx={{ width: "100%" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatchSiteState({
                  zipPostalCode: event.target.value as string,
                  type: SiteActionType.UPDATE_ZIP_POSTAL_CODE,
                });
                setShowBtns(true);
              }}
              disabled={!modifyAccess}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: 0,
              gap: "8px",
              width: "100%",
            }}
            onClick={() => {
              setShowBtns(true);
            }}
          >
            <Typography variant="subtitle1">Company</Typography>
            <FixedHeightTextField
              variant="outlined"
              value={siteState.company}
              sx={{ width: "100%" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatchSiteState({ company: event.target.value as string, type: SiteActionType.UPDATE_COMPANY });
                setShowBtns(true);
              }}
              disabled={!modifyAccess}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: 0,
            gap: "8px",
            width: "50%",
          }}
          onClick={() => {
            setShowBtns(true);
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1">Key Contact</Typography>
            <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
              *
            </Typography>
            <Box style={{ paddingLeft: "8px" }}>
              <LightTooltip title="The key contact will be automatically granted access to this site.">
                <InfoOutlinedIcon style={{ color: "#555758", fontSize: "16px" }} />
              </LightTooltip>
            </Box>
          </Box>
          {modifyAccess ? (
            <SiteReducerFilter
              options={userOptions}
              siteState={siteState}
              stateKey={"keyContact"}
              dispatchSiteState={dispatchSiteState}
              action={SiteActionType.UPDATE_KEY_CONTACT}
            />
          ) : (
            <Typography variant="body1">{siteState.keyContact.displayVal}</Typography>
          )}
          <RequiredFieldMsg style={{ display: !hasContact ? "" : "none" }} />
        </Box>
        {site?.keyContactObj && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "24px",
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px",
                gap: "8px",
                width: "100%",
              }}
            >
              <MailOutlineSharpIcon />
              <Typography variant="body1">{fullUser?.email || "No data available"}</Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px",
                gap: "8px",
                width: "100%",
              }}
            >
              <SharpPhoneIcon />
              <Typography variant="body1">{fullUser?.phoneNumber || "No data available"}</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <SettingsBottomBar show={showBtns} hasRequiredFields={hasName && hasContact}>
        <>
          <BaseButton
            variant="outlined"
            onClick={() => {
              handleCancel();
            }}
            disabled={!modifyAccess}
          >
            Cancel
          </BaseButton>
          <BaseButton variant="contained" onClick={handleUpdateSite}>
            Save Changes
          </BaseButton>
        </>
      </SettingsBottomBar>
    </>
  );
};

export default SiteInfoTab;
