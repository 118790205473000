import { ChevronRightOutlined } from "@mui/icons-material";
import { Typography, Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useLazyGetSitesDictQuery, SitesDict } from "redux/api/appApi";
import useSWR from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import SiteUsersTab from "../Users/SiteUsersTab";
import SiteInfoTab from "./SiteInfoTab";
import { getSiteUsers } from "./SitesPage";
import { CommonPageContext } from "components/CommonPage/CommonPageContext";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import LoadingDialog from "components/Dialog/LoadingDialog";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRouter } from "hooks/useRouter";
import useUsers from "hooks/useUsers";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { SingleSite } from "services/ContentServer/Map";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

export interface SiteEditProps {
  site?: SingleSite;
}

const SiteEditPage = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [processingMsg, setProcessingMsg] = useState("");
  const [siteEditProps, setSiteEditProps] = useState<SiteEditProps | undefined>(undefined);
  const [siteInfo, setSiteInfo] = useState<SiteEditProps | undefined>();
  const [sitesDict, setSitesDict] = useState<SitesDict | undefined>(undefined);
  const [getSitesDict, results] = useLazyGetSitesDictQuery();
  const { getStringQuery } = useRouter();

  const { profiles, users, featureAccess } = useUsers();
  const { contentServer } = useContext(RequestContext);
  const { setIsSiteCreating } = useContext(CommonPageContext);

  const siteAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.SITE];
  }, [featureAccess]);

  const { data: objPermissions } = useSWR([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS], () =>
    contentServer.objectPermissionService.list([["content_type", "site"]])
  );

  const selectedSiteId = useMemo(() => {
    return getStringQuery("siteId");
  }, [getStringQuery]);

  useEffect(() => {
    if (selectedSiteId) {
      getSitesDict()
        .unwrap()
        .then(() => {
          setSitesDict(results.data);
        });
    }
  }, [selectedSiteId, getSitesDict, results.data]);

  useEffect(() => {
    if (selectedSiteId !== undefined && sitesDict && sitesDict[selectedSiteId]) {
      const selectedSite = {
        id: selectedSiteId,
        name: sitesDict[selectedSiteId].name,
        keyContact: profiles[sitesDict[selectedSiteId].keyContact]?.displayName || "",
        users: {},
        address: sitesDict[selectedSiteId].address,
        country: sitesDict[selectedSiteId].country,
        region: sitesDict[selectedSiteId].region,
        zipPostalCode: sitesDict[selectedSiteId].zipPostalCode,
        company: sitesDict[selectedSiteId].company,
        keyContactObj: undefined,
      } as SingleSite;
      if (siteAccess.update) {
        selectedSite.keyContactObj = Object.values(profiles).find(
          (profile) => profile.displayName === selectedSite.keyContact
        );
      }
      setSiteInfo({ site: selectedSite });
    }
  }, [selectedSiteId, sitesDict, profiles, siteAccess.update, getSitesDict, results.data]);

  useEffect(() => {
    if (selectedSiteId === "" || selectedSiteId === undefined) {
      setSiteEditProps({
        site: {
          id: "",
          name: "",
          address: "",
          country: "",
          region: "",
          zipPostalCode: "",
          company: "",
          keyContactObj: {},
          users: {},
        } as SingleSite,
      });
    } else if (sitesDict && sitesDict[selectedSiteId]) {
      const selectedSite = {
        id: selectedSiteId,
        name: sitesDict[selectedSiteId].name,
        keyContact: profiles[sitesDict[selectedSiteId].keyContact]?.displayName || "",
        users: getSiteUsers(selectedSiteId, objPermissions, users),
        address: sitesDict[selectedSiteId].address,
        country: sitesDict[selectedSiteId].country,
        region: sitesDict[selectedSiteId].region,
        zipPostalCode: sitesDict[selectedSiteId].zipPostalCode,
        company: sitesDict[selectedSiteId].company,
        keyContactObj: undefined,
      } as SingleSite;
      if (siteAccess.update) {
        selectedSite.keyContactObj = Object.values(profiles).find(
          (profile) => profile.displayName === selectedSite.keyContact
        );
      }
      setSiteEditProps({ site: selectedSite });
    }
  }, [selectedSiteId, sitesDict, profiles, objPermissions, users, siteAccess.update]);
  const site = useMemo(() => {
    if (siteEditProps) {
      return siteEditProps.site;
    }
    return undefined;
  }, [siteEditProps]);

  const InfoComponent = useMemo(() => {
    return (
      siteInfo && (
        <SiteInfoTab
          siteEditProps={siteInfo}
          setSiteEditProps={setSiteInfo}
          key={2}
          setProcessing={setProcessing}
          setProcessingMsg={setProcessingMsg}
        />
      )
    );
  }, [siteInfo]);

  const UsersComponent = useMemo(() => {
    return (
      site && (
        <SiteUsersTab
          siteEditProps={siteEditProps}
          key={1}
          setProcessing={setProcessing}
          setProcessingMsg={setProcessingMsg}
        />
      )
    );
  }, [site, siteEditProps]);

  const TabComponents = useMemo(() => {
    if (site) {
      setIsSiteCreating(false);
      if (site.id === "") {
        return [InfoComponent];
      } else {
        return [InfoComponent, UsersComponent];
      }
    }
    return [];
  }, [site, InfoComponent, UsersComponent, setIsSiteCreating]);

  return (
    <>
      <LoadingDialog processing={processing} msg={processingMsg} />
      <main style={{ backgroundColor: "white", width: "55%" }}>
        <Grid container direction="row" spacing={3} style={{ paddingLeft: "80px", paddingTop: "48px" }}>
          <Grid item xs={12}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Link to={SettingRoutesDict.get(SettingTab.SITES)?.path ?? ""} style={{ textDecoration: "none" }}>
                <Typography variant="h1" sx={{ paddingBottom: 0, "&:hover": { textDecoration: "underline" } }}>
                  Sites
                </Typography>
              </Link>
              <ChevronRightOutlined style={{ color: theme.palette.primary.main }} />
              <Typography variant="h1" style={{ paddingBottom: 0 }}>
                {site ? site.name : "Unnamed Site"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: "solid 1px #e1e2e4" }}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <HeaderTabs
                  tab={tab}
                  setTab={setTab}
                  headers={
                    site && site.id ? ["Information", `Users (${Object.values(site.users).length})`] : ["Information"]
                  }
                />
              </Box>
            </Box>
          </Grid>
          {TabComponents[tab]}
        </Grid>
      </main>
    </>
  );
};

export default SiteEditPage;
