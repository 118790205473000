import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography, Grid, Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useMemo, useState, useCallback, useReducer } from "react";
import { IFolder } from "redux/api/folderApi";
import { ISite, useAddSiteMutation } from "redux/api/siteApi";
import { mutate } from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import { CommonPageContext } from "components/CommonPage/CommonPageContext";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import LightTooltip from "components/DalmatianDesignComponents/LightTooltip";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { MapSWRKeys } from "services/ContentServer/Map";
import { initialSiteState, SiteActionType, siteReducer } from "views/Sites/SiteManagement/siteReducer";
import SiteReducerFilter from "views/Sites/SiteManagement/SiteReducerFilter";

const SitesNewSiteDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { users } = useUsers();
  const { history } = useRouter();
  const { setIsSiteCreating } = useContext(CommonPageContext);
  const snackbar = useSnackbar();
  const [siteState, dispatchSiteState] = useReducer(siteReducer, initialSiteState);
  const hasName = useMemo(() => siteState.name !== "", [siteState]);
  const [displayReqSiteError, setDisplayReqSiteError] = useState(false);
  const hasContact = useMemo(() => siteState.keyContact.data, [siteState]);
  const [displayReqContactError, setDisplayReqContactError] = useState(false);
  const [addSite] = useAddSiteMutation();

  const userOptions = useMemo(() => {
    return Object.values(users)
      .filter((user) => user.isActive)
      .map((user) => {
        return {
          data: user.userProfile.id,
          displayVal: user.userProfile.displayName,
        };
      });
  }, [users]);

  const navigateToEditPage = useCallback(
    (siteId: string) => {
      history.push(`${SettingRoutesDict.get(SettingTab.SITE_EDIT)?.path}?siteId=${siteId}`, "new");
    },
    [history]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setDisplayReqContactError(false);
    setDisplayReqSiteError(false);
    dispatchSiteState({
      site: undefined,
      keyContact: {
        data: "",
        displayVal: "",
      },
      type: SiteActionType.RESET_ALL,
    });
  }, [setOpen, setDisplayReqContactError, setDisplayReqSiteError]);

  const handleSubmit = async () => {
    setDisplayReqSiteError(!hasName);
    setDisplayReqContactError(!hasContact);
    if (hasName && hasContact) {
      handleClose();
      setIsSiteCreating(true);

      addSite({
        name: siteState.name,
        contactId: siteState.keyContact.data,
      } as IFolder & ISite)
        .unwrap()
        .then((newSite: IFolder & ISite) => {
          mutate(MapSWRKeys.SITES);
          navigateToEditPage(newSite.id);
        })
        .catch((error) => {
          console.error(error);
          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Error saving site changes.",
          });
        });
      await mutate(MapSWRKeys.SITES);
      await mutate([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS]);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          borderRadius: 0,
          boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px 24px 24px",
          gap: "24px",
          width: "576px",
          height: "auto",
        },
        width: "100%",
      }}
    >
      <DialogTitle
        style={{ width: "100%", padding: "24px 0px 16px 0px", borderBottom: "1px solid rgba(212, 214, 217, 0.7)" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Typography variant="h3">Create New Site</Typography>
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: "0", margin: "auto" }}>
        {/* <LoadingDialog processing={processing} msg={processingMsg} /> */}
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                style={{
                  paddingBottom: "5px",
                }}
              >
                Site Name
              </Typography>
            </Box>
            <FixedHeightTextField
              variant="outlined"
              required={true}
              placeholder={""}
              value={siteState.name}
              sx={{ width: "100%" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatchSiteState({ name: event.target.value, type: SiteActionType.UPDATE_NAME });
              }}
            />
            <RequiredFieldMsg style={{ display: displayReqSiteError ? "" : "none" }} />
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle1">Key Contact</Typography>
              <Box style={{ paddingLeft: "8px" }}>
                <LightTooltip title="The key contact will be automatically granted access to this site.">
                  <InfoOutlinedIcon style={{ color: "#555758", fontSize: "16px" }} />
                </LightTooltip>
              </Box>
            </Box>
            <SiteReducerFilter
              options={userOptions}
              siteState={siteState}
              stateKey={"keyContact"}
              dispatchSiteState={dispatchSiteState}
              action={SiteActionType.UPDATE_KEY_CONTACT}
              placeholderText={"None selected"}
            />
            <RequiredFieldMsg style={{ display: displayReqContactError ? "" : "none" }} />
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            paddingTop: "10px",
          }}
        >
          Any users with access to ‘All Sites’ will be automatically granted access to this site. Access permissions can
          be changed in the users tab.
        </Typography>
      </DialogContent>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              alignContent: "flex-endcenter",
              gap: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <BaseButton variant="outlined" onClick={handleClose}>
              Cancel
            </BaseButton>
            <BaseButton variant="contained" onClick={handleSubmit}>
              Create Site
            </BaseButton>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SitesNewSiteDialog;
