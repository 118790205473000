import { APIInstance } from "../../APIInstance";
import { ENDPOINTS, INVITE_KEY_ENDPOINTS, LOCK_ENDPOINTS } from "../constants";
import { ObjectPermissions, PermissionValues } from "../serviceTypes/ObjectPermissions";
import { Snapshot } from "../serviceTypes/Snapshot";
import { ARCHIVE_ENDPOINTS } from "./../constants";
import { ServiceBase } from "services/ContentServer/ServiceBase";
import serialize from "utils/ObjectToFormData";

export const SnapshotSWRKeys = {
  SIMPLE_SNAPSHOT_TEMPLATES: "simpleTemplateSnapshots",
  SNAPSHOT_TEMPLATES: "templateSnapshots",
  SNAPSHOT_TEMPLATE: "templateSnapshot",
  SIMPLE_SNAPSHOTS: "simpleSnapshots",
  SNAPSHOTS: "snapshots",
  SNAPSHOT: "snapshot",
  BASIC_SNAPSHOT_TEMPLATES: "basicTemplateSnapshots",
  ARCHIVED: "archivedSnapshot",
};

export const SnapshotGetFields = {
  SIMPLE_SNAPSHOTS: ["id", "name", "asset", "is_modifiable", "facility", "last_modified_at", "asset_id", "description"],
  SIMPLE_SNAPSHOT_TEMPLATES: ["id", "name", "specifier", "is_modifiable"],
};

export class SnapshotService extends ServiceBase<Snapshot> {
  constructor(apiInstance: APIInstance) {
    super(Snapshot, apiInstance, ENDPOINTS.SNAPSHOTS);
  }

  async updateLock(id: string, lock: boolean) {
    return this.get(id, undefined, lock ? LOCK_ENDPOINTS.LOCK_MODEL : LOCK_ENDPOINTS.UNLOCK_MODEL);
  }

  async updateArchive(id: string, archive: boolean) {
    return this.get(id, undefined, archive ? ARCHIVE_ENDPOINTS.ARCHIVE : ARCHIVE_ENDPOINTS.UNARCHIVE);
  }

  async updateInviteKey(id: string, invite: boolean) {
    return this.get(id, undefined, invite ? INVITE_KEY_ENDPOINTS.GENERATE : INVITE_KEY_ENDPOINTS.CLEAR);
  }

  async getSafe(
    id: string,
    userObjPerms: ObjectPermissions[],
    searchParams?: [string, string][],
    urlPath?: string,
    getFields?: string[]
  ) {
    const resp = await this.get(id, searchParams, urlPath, getFields);
    const thisObjPerm = userObjPerms.find((perm) => perm.objectId === resp.siteId);

    if (thisObjPerm && thisObjPerm.permissions?.view === PermissionValues.noPermission) {
      return undefined;
    }
    return resp;
  }

  async import(
    jsonFile: any,
    importStatus: string,
    siteID: string,
    uuid: string,
    currentChunk: string,
    totalChunk: string,
    zipName: string
  ) {
    return this.postCustomFormData(
      {
        file: jsonFile,
        status: importStatus,
        site: siteID,
        uuid: uuid,
        currentChunk: currentChunk,
        totalChunk: totalChunk,
        zipFileName: zipName,
      },
      ENDPOINTS.REQUEST_IMPORT,
      { "UPLOAD-DATA": uuid + ", " + currentChunk + ", " + totalChunk }
    );
  }

  async updateFiles(id: string, removed: string[], snapshots: Snapshot[]) {
    const request = {
      segments: snapshots.map((snap) => {
        snap.segments?.map((seg) => seg.serialize());
      }),
    };
    const serialized = serialize(request);
    removed.forEach((val) => {
      serialized.append("removed_files", val);
    });

    return this.patchCustomFormData(id, serialized, false);
  }
}
