export function AddToQuery(prev: string, ...args: [string, any][]): string {
  args.forEach((arg) => {
    if (arg[0] !== "" && arg[1]) {
      const elem = `${arg[0]}=${encodeURIComponent(arg[1])}`;
      if (prev.includes("?")) {
        prev += "&" + elem;
      } else {
        prev += "?" + elem;
      }
    }
  });
  return prev;
}

export function addOrReplaceQuery(prev: string, arg: [string, any]): string {
  if (prev.includes("?") && (prev.includes(`?${arg[0]}=`) || prev.includes(`&${arg[0]}=`))) {
    const words = prev.split("?");
    const keyVals = words[1].split("&");
    let url = words[0] + "?";

    keyVals.forEach((pair, idx) => {
      if (!pair.includes(arg[0] + "=")) {
        if (idx === 0) {
          url = url + pair;
        } else {
          url = url + "&" + pair;
        }
      } else {
        if (idx === 0) {
          url = url + arg[0] + "=" + encodeURIComponent(arg[1]);
        } else {
          url = url + "&" + arg[0] + "=" + encodeURIComponent(arg[1]);
        }
      }
    });

    return url;
  } else {
    return AddToQuery(prev, arg);
  }
}

export function removeFromURL(prev: string, arg: [string, any]): string {
  let url = "";
  const searchFields = prev.substring(1).split("&");

  searchFields.forEach((field) => {
    if (!field.includes(arg[0] + "=" + encodeURIComponent(arg[1]))) {
      if (url === "") {
        url = "?" + field;
      } else {
        url = url + "&" + field;
      }
    }
  });
  return url;
}
