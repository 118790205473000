import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import Config from "Config";
import { DocumentNode } from "graphql";
import { ClientError } from "graphql-request";
import { setToken } from "redux/features/authSlice";
import type { RootState } from "redux/store";

import { msalInstance } from "services";

const baseQuery = graphqlRequestBaseQuery<Partial<ClientError> & { statusCode: number; error: string }>({
  url: Config.BACKEND_BASE_URL + "/graphql/",
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.auth.token;
    const apiVersion = state.auth.apiVersion;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("version", apiVersion);
    }

    return headers;
  },
  customErrors: ({ name, stack, response }) => {
    const error = response?.errors?.length ? response?.errors[0]?.extensions?.code : "";
    const message = response?.errors?.length ? response?.errors[0]?.message : "";
    const statusCode = response?.errors?.length ? response?.errors[0]?.extensions?.response?.statusCode : 500;

    return {
      name,
      message,
      statusCode,
      error,
      stack,
    };
  },
});

const baseQueryWithReauth: BaseQueryFn<
  {
    document: string | DocumentNode;
    variables?: any;
  },
  unknown,
  Partial<ClientError> & { statusCode: number; error: string }
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.statusCode === 500) {
    try {
      // try to get a new token
      const resp = await msalInstance.acquireTokenSilent({
        scopes: Config.AZURE_AD_CONFIG.backendScopes,
        account: (api.getState() as RootState).auth.account,
        redirectUri: Config.AZURE_AD_CONFIG.redirectUri,
      });
      api.dispatch(setToken(resp.accessToken));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } catch (error) {
      console.error(error);
    }
  }

  return result;
};

export default baseQueryWithReauth;
