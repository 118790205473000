import { SortRounded, ExpandMore } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  styled,
  TextField,
  IconButton,
  PopperProps,
  autocompleteClasses,
  ListItemIcon,
  MenuList,
  ListItemText,
  Typography,
  ListItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { $enum } from "ts-enum-util";

import { ACTIVE_BUTTONS } from "./Panels/Constants";
import MenuItem from "components/menu/MenuItem";
import Popover from "components/popover/Popover";
import Tooltip from "components/Tooltip/Tooltip";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useRouter } from "hooks/useRouter";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { SnapshotContext } from "utils/Contexts/SnapshotContext";
import { getMonthDayTime } from "utils/DateUtils";
import { sortObjectsByString, sortObjectsByTime } from "utils/SortingUtils";

export const InvisibleTextField = styled(TextField)({
  "& label.Mui-focused": {
    display: "none",
  },
  "& label.Mui": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

export const AutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    backgroundColor: theme.palette.primary.contrastText,
    minWidth: 300,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    [`& .${autocompleteClasses.option}`]: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "140%",
      minHeight: "auto",
      alignItems: "flex-start",
      padding: "8px 25px",
      border: "none",
      color: theme.palette.primary.main,

      '&[aria-selected="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
      '&[data-focus="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
    },
  },
}));

export function PopperComponent(props: PopperProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <AutocompletePopper {...other} />;
}

export enum SortSelection {
  CREATED = "snapshot creation date",
  ALPHABETICAL = "name",
}

const SnapshotPicker = ({
  selectedSnapshot,
  small = false,
  filterByFacility = false,
  disableSelection = false,
}: {
  selectedSnapshot: Snapshot | undefined;
  small?: boolean;
  filterByFacility?: boolean;
  disableSelection?: boolean;
}) => {
  const theme = useTheme();
  const [snapshotSearchAnchorEl, setSnapshotSearchAnchorEl] = useState<HTMLElement | null>(null);
  const [sortSnapshotsAnchorEl, setSortSnapshotsAnchorEl] = useState<HTMLElement | null>(null);
  const sortButtonRef = useRef<HTMLButtonElement | null>(null);
  const snapshotNameRef = useRef<HTMLHRElement | null>(null);
  const isMobile = useARMediaQuery("mobile");
  const isTablet = useARMediaQuery("tablet");

  const { handleChangeSnapshot } = useRouter();

  const [snapshotSortBy, setSnapshotSortBy] = useState<SortSelection>(SortSelection.CREATED);

  const { snapshots: siteSnapshots, snapshotsLoading } = useContext(SnapshotContext);

  const assetSnapshots = useMemo(() => {
    const filteredSnapshots = siteSnapshots?.filter(
      (snap) =>
        snap?.assetId === selectedSnapshot?.assetId &&
        (!filterByFacility || snap.facility === selectedSnapshot?.facility)
    );

    return filteredSnapshots?.sort((a, b) => {
      const aName = getMonthDayTime(a?.createdAt);
      const bName = getMonthDayTime(b?.createdAt);
      switch (snapshotSortBy) {
        case SortSelection.CREATED:
          return sortObjectsByTime(a.createdAt, b.createdAt);
        case SortSelection.ALPHABETICAL:
          return sortObjectsByString(aName, bName);
      }
    });
  }, [siteSnapshots, selectedSnapshot, snapshotSortBy, filterByFacility]);

  const handleSnapshotChevronClick = useCallback(() => {
    setSnapshotSearchAnchorEl(snapshotNameRef.current);
  }, [snapshotNameRef]);

  const disableText = useMemo(() => {
    if (snapshotsLoading) {
      return "Snapshots are loading...";
    }
    if ((assetSnapshots !== undefined && assetSnapshots.length <= 1) || disableSelection) {
      return "No other snapshots available";
    }
    return undefined;
  }, [assetSnapshots, disableSelection, snapshotsLoading]);

  return (
    <div ref={snapshotNameRef}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Tooltip
          title={disableText || "See more snapshots"}
          PopperProps={{ disablePortal: true }}
          disableHoverListener={disableSelection}
        >
          <IconButton
            disableRipple
            onClick={!disableText ? handleSnapshotChevronClick : () => {}}
            sx={{
              backgroundColor: snapshotSearchAnchorEl ? ACTIVE_BUTTONS.BACKGROUND_COLOUR : "",
              px: isMobile || isTablet ? 2 : 0,
              py: isMobile || isTablet ? 1 : 0,
              borderRadius: "4px",
            }}
            size="large"
          >
            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography
                variant="h5"
                style={{
                  maxWidth: 250,
                  textOverflow: "ellipsis",
                  fontSize: small ? "0.875rem" : "1.125rem",
                  color: theme.palette.primary.main,
                  marginRight: small ? "4px" : "5px",
                }}
                noWrap
              >
                {getMonthDayTime(selectedSnapshot?.createdAt)}
              </Typography>
            </Box>
            {!disableSelection && (
              <ExpandMore
                style={{
                  width: 22.3,
                  height: 22.3,
                  color: small ? "#64676A" : theme.palette.primary.main,
                  transform: snapshotSearchAnchorEl ? "rotate(0.5turn)" : "none",
                }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        open={Boolean(snapshotSearchAnchorEl)}
        anchorEl={snapshotSearchAnchorEl}
        onClose={() => setSnapshotSearchAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        disablePortal
        PaperProps={{
          style: {
            background: theme.palette.primary.contrastText,
            border: "none",
            padding: 0,
            marginTop: -5,
            minWidth: 300,
            maxHeight: isMobile || isTablet ? 300 : "auto",
          },
        }}
      >
        <Autocomplete
          size="small"
          options={assetSnapshots || []}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return "";
            }
            return getMonthDayTime(option.createdAt);
          }}
          onChange={(_, newValue, reason) => {
            if (reason === "selectOption" && newValue && typeof newValue !== "string") {
              handleChangeSnapshot(newValue);
              setSnapshotSearchAnchorEl(null);
            }
          }}
          renderInput={(params) => {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="left" ref={sortButtonRef}>
                <SearchIcon
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
                <InvisibleTextField
                  {...params}
                  placeholder="Search snapshots..."
                  variant="outlined"
                  hiddenLabel={true}
                />
                <IconButton
                  onClick={() => setSortSnapshotsAnchorEl(sortButtonRef.current)}
                  style={{ width: 25, height: 25, margin: 10 }}
                  size="large"
                >
                  <SortRounded />
                </IconButton>
              </Box>
            );
          }}
          PopperComponent={PopperComponent}
          open
          freeSolo
          ListboxProps={{ style: { paddingTop: 0 } }}
          style={{ border: "none" }}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              key={option.id}
              sx={{
                backgroundColor:
                  selectedSnapshot && option.id === selectedSnapshot.id
                    ? theme.palette.secondary.dark
                    : theme.palette.primary.contrastText,
                "&:hover": {
                  backgroundColor:
                    selectedSnapshot && option.id === selectedSnapshot.id
                      ? `${theme.palette.secondary.dark} !important`
                      : theme.palette.secondary.main,
                },
              }}
            >
              {getMonthDayTime(option.createdAt)}
            </ListItem>
          )}
        />
      </Popover>
      <Popover
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(sortSnapshotsAnchorEl)}
        onClose={() => setSortSnapshotsAnchorEl(null)}
        anchorEl={sortSnapshotsAnchorEl}
        disablePortal
        PaperProps={{
          style: {
            background: theme.palette.primary.contrastText,
            border: "none",
            padding: 0,
            minWidth: 200,
          },
        }}
      >
        <MenuList>
          {$enum(SortSelection)
            .getValues()
            .map((sortBy) => (
              <MenuItem key={`snapshot-picker-sort-by-${sortBy}`} onClick={() => setSnapshotSortBy(sortBy)}>
                <ListItemIcon style={{ visibility: snapshotSortBy === sortBy ? "inherit" : "hidden" }}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>Sort by {sortBy}</ListItemText>
              </MenuItem>
            ))}
        </MenuList>
      </Popover>
    </div>
  );
};

export default SnapshotPicker;
