import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

import { SelectionView } from "utils/Contexts/SnapshotSelectionContext";

export interface IAppState {
  selectedSiteId: string;
  selectionView: SelectionView;
  previousSnapshotId: string;
  //navigation drawer
  drawerWidth: number;
  selectedNavItem: string;
  openChat: boolean;
  chatMsgs: number;
  openSettingsNav: boolean;
}

const initialState: IAppState = {
  selectedSiteId: "",
  selectionView: SelectionView.NotSelecting,
  previousSnapshotId: "",
  //navigation drawer
  drawerWidth: 64,
  selectedNavItem: "",
  openChat: false,
  chatMsgs: 0,
  openSettingsNav: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedSiteId: (state, action: PayloadAction<string>) => {
      state.selectedSiteId = action.payload;
    },
    setSelectedView: (state, action: PayloadAction<SelectionView>) => {
      state.selectionView = action.payload;
    },
    setPreviousSnapshotId: (state, action: PayloadAction<string>) => {
      state.previousSnapshotId = action.payload;
    },
    //navigation drawer
    setDrawerWidth: (state, action: PayloadAction<number>) => {
      state.drawerWidth = action.payload;
    },
    setSelectedNavItem: (state, action: PayloadAction<string>) => {
      state.selectedNavItem = action.payload;
    },
    setOpenChat: (state, action: PayloadAction<boolean>) => {
      state.openChat = action.payload;
    },
    setChatMsgs: (state, action: PayloadAction<number>) => {
      state.chatMsgs = action.payload;
    },
    setOpenSettingsNav: (state, action: PayloadAction<boolean>) => {
      state.openSettingsNav = action.payload;
    },
  },
});

export const {
  setSelectedSiteId,
  setSelectedView,
  setPreviousSnapshotId,
  setDrawerWidth,
  setOpenChat,
  setSelectedNavItem,
  setChatMsgs,
  setOpenSettingsNav,
} = appSlice.actions;

export const getSelectedSiteId = (state: RootState) => state.app.selectedSiteId;
export const getSelectedView = (state: RootState) => state.app.selectionView;
export const getPreviousSnapshotId = (state: RootState) => state.app.previousSnapshotId;
//navigation drawer
export const getDrawerWidth = (state: RootState) => state.app.drawerWidth;
export const getSelectedNavItem = (state: RootState) => state.app.selectedNavItem;
export const getOpenChat = (state: RootState) => state.app.openChat;
export const getChatMsgs = (state: RootState) => state.app.chatMsgs;
export const getOpenSettingsNav = (state: RootState) => state.app.openSettingsNav;

export default appSlice;
