import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "./customFetchBase";

export enum CUSTOM_FIELD_TYPE {
  SNAPSHOT = "SNAPSHOT",
  CAPTURE = "CAPTURE",
  READ_ONLY_CAPTURE = "READ_ONLY_CAPTURE",
}

export enum CUSTOM_FIELD_STATUS {
  NEW = "NEW",
  DELETED = "DELETED",
  PENDING = "PENDING",
  NORMAL = "NORMAL",
}

export interface ICustomField {
  id: string;
  title: string;
  details: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
}

export interface IBatchCustomField {
  addFields?: Partial<ICustomField>[];
  updateFields?: Partial<ICustomField>[];
  removeFields?: string[];
}

const deserializeCustomFields = (obj: any): ICustomField => {
  const objMap: ICustomField = {
    id: obj.id,
    title: obj.title,
    details: obj.details,
    lastModifiedAt: obj["last_modified_at"],
    lastModifiedBy: obj["last_modified_by"],
  };

  return objMap;
};

const serializePost = (type: CUSTOM_FIELD_TYPE, id: string, customField: Partial<ICustomField>) => {
  const formData = new FormData();

  formData.append(getTypeId(type), id);
  if (customField.title && customField.title.length) formData.append("title", customField.title);
  if (customField.details && customField.details.length) formData.append("details", customField.details);

  return formData;
};

const serialize = (customField: Partial<ICustomField>) => {
  const formData = new FormData();

  if (customField.title && customField.title.length) formData.append("title", customField.title);
  if (customField.details && customField.details.length) formData.append("details", customField.details);

  return formData;
};

const serializeBatch = (data: IBatchCustomField) => {
  const result: any = {};

  if (data.addFields) result["add_fields"] = data.addFields;
  if (data.updateFields) result["update_fields"] = data.updateFields;
  if (data.removeFields) result["remove_fields"] = data.removeFields;

  return result;
};

const serializeSnapshotBlob = (id: string, captureIds: string[]) => {
  const result: any = {};

  result["snapshot_id"] = id;
  if (captureIds && captureIds.length) result["capture_ids"] = captureIds;

  return result;
};

const customFieldUrl = (type: CUSTOM_FIELD_TYPE) => {
  switch (type) {
    case CUSTOM_FIELD_TYPE.SNAPSHOT:
      return "customfields/snapshots/";

    case CUSTOM_FIELD_TYPE.CAPTURE:
      return "customfields/captures/";

    case CUSTOM_FIELD_TYPE.READ_ONLY_CAPTURE:
      return "customfields/captures/read/";
  }
};

const getTypeId = (type: CUSTOM_FIELD_TYPE) => {
  switch (type) {
    case CUSTOM_FIELD_TYPE.SNAPSHOT:
      return "snapshot_id";
    case CUSTOM_FIELD_TYPE.CAPTURE:
      return "capture_id";
    case CUSTOM_FIELD_TYPE.READ_ONLY_CAPTURE:
      return "capture_id";
  }
};

export const customFieldApi = createApi({
  reducerPath: "customFieldApi",
  baseQuery: customFetchBase,
  tagTypes: ["CustomFields"],
  endpoints: (builder) => ({
    getCustomFields: builder.query<
      ICustomField[],
      { type: CUSTOM_FIELD_TYPE; id: string; snapshotId?: string; key?: string }
    >({
      query({ type, id, snapshotId, key }) {
        return type === CUSTOM_FIELD_TYPE.READ_ONLY_CAPTURE
          ? { url: `${customFieldUrl(type)}/${snapshotId}/${key}?${getTypeId(type)}=${id}&order_by=created_at` }
          : { url: `${customFieldUrl(type)}?${getTypeId(type)}=${id}&order_by=created_at` };
      },
      transformResponse: (response: any) => {
        return response.map((item: any) => deserializeCustomFields(item));
      },
      providesTags: (result) =>
        result
          ? // successful query
            [...result.map(({ id }) => ({ type: "CustomFields", id } as const)), { type: "CustomFields", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "CustomFields", id: "LIST" }],
    }),
    createCustomField: builder.mutation<
      ICustomField,
      { type: CUSTOM_FIELD_TYPE; id: string; data: Partial<ICustomField> }
    >({
      query({ type, id, data }) {
        return {
          url: customFieldUrl(type),
          method: "POST",
          body: serializePost(type, id, data),
        };
      },
      invalidatesTags: (result, error, { data }) => [
        { type: "CustomFields", id: data.id },
        { type: "CustomFields", id: "LIST" },
      ],
    }),
    updateCustomField: builder.mutation<ICustomField, { type: CUSTOM_FIELD_TYPE; data: Partial<ICustomField> }>({
      query({ type, data }) {
        return {
          url: `${customFieldUrl(type)}${data.id}/`,
          method: "PATCH",
          body: serialize(data),
        };
      },
      invalidatesTags: (result, error, { data }) => [
        { type: "CustomFields", id: data.id },
        { type: "CustomFields", id: "LIST" },
      ],
    }),
    deleteCustomField: builder.mutation<ICustomField, { type: CUSTOM_FIELD_TYPE; id: string }>({
      query({ type, id }) {
        return {
          url: `${customFieldUrl(type)}${id}/`,
          method: "Delete",
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "CustomFields", id },
        { type: "CustomFields", id: "LIST" },
      ],
    }),
    unDeleteCustomField: builder.mutation<ICustomField, { type: CUSTOM_FIELD_TYPE; id: string }>({
      query({ type, id }) {
        return {
          url: `${customFieldUrl(type)}${id}/undelete/`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "CustomFields", id },
        { type: "CustomFields", id: "LIST" },
      ],
    }),
    batchUpdateSnapshot: builder.mutation<ICustomField[], { id: string; data: IBatchCustomField }>({
      query({ id, data }) {
        return {
          url: `snapshots/${id}/`,
          method: "PATCH",
          body: serializeBatch(data),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "CustomFields", id: id },
        { type: "CustomFields", id: "LIST" },
      ],
    }),
    getSnapshotBlobUrl: builder.mutation<string, { id: string; captureIds: string[] }>({
      query({ id, captureIds }) {
        return {
          url: `powerautomate/snapshot/`,
          method: "POST",
          body: serializeSnapshotBlob(id, captureIds),
        };
      },
    }),
  }),
});

export const {
  useGetCustomFieldsQuery,
  useLazyGetCustomFieldsQuery,
  useCreateCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldMutation,
  useUnDeleteCustomFieldMutation,
  useBatchUpdateSnapshotMutation,
  useGetSnapshotBlobUrlMutation,
} = customFieldApi;
