import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { ObjectPermissions } from "../serviceTypes/ObjectPermissions";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const ObjectPerissionSWRKeys = {
  OBJECT_PERMISSIONS: "ObjectPermissions",
};
export class ObjectPermissionsService extends ServiceBase<ObjectPermissions> {
  constructor(apiInstance: APIInstance) {
    super(ObjectPermissions, apiInstance, ENDPOINTS.OBJECT_PERMISSIONS);
  }
}
