import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export interface ObjectPermissionType {
  add: string;
  change: string;
  delete: string;
  view: string;
}

export enum PermissionValues {
  hasPermission = "1",
  noPermission = "0",
}

export const serverToView: { [key: string]: keyof ObjectPermissions | undefined } = {
  id: "id",
  content_type: "contentType",
  object_id: "objectId",
  username: "username",
  permissions: "permissions",
};

export class ObjectPermissions extends ISerialization {
  public id?: string | null;
  public contentType?: any | null;
  public objectId?: string | null;
  public username?: string | null;
  public permissions?: ObjectPermissionType | null;

  constructor(permissions?: ObjectPermissions) {
    super();
    Object.assign(this, permissions);
  }

  deserialize(json: any): void {
    deserializerHelper<ObjectPermissions>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<ObjectPermissions>(this, serverToView);
    if (this.permissions !== undefined) {
      request.permissions = JSON.stringify(this.permissions);
    }
    return request;
  }
}
