import { Box, Typography, FormControl, useTheme } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGetSitesDictQuery } from "redux/api/appApi";
import { useGetTeamsQuery } from "redux/api/teamApi";
import { TenantFeatureEnum } from "redux/api/types";
import { useUpdateUserMutation, useUpdateUserProfileMutation } from "redux/api/userApi";
import { IUserProfile, useGetUserProfileByIdQuery, useUpdateUserProfileTeamMutation } from "redux/api/userProfileApi";
import useApp from "redux/hooks/useApp";
import useSWR, { mutate } from "swr";

import { CommonPageContext } from "components/CommonPage/CommonPageContext";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { FilterPopup } from "components/DalmatianDesignComponents/DropdownFilter";
import { SearchOption } from "components/DalmatianDesignComponents/SearchBar";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { ObjectPermissions, PermissionValues } from "services/ContentServer/Audit/serviceTypes/ObjectPermissions";
import { hasProfile, Profile, Role, RoleIDList, User, UserPending, UserTypes } from "services/ContentServer/Identity";
import { UsersSWRKeys } from "services/ContentServer/Identity/constants";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { UserInvite } from "services/ContentServer/Identity/serviceTypes/UserInvite";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import TeamSelectionField, { TeamData } from "views/Audit/TeamSelectionField";

const ProfilePemissionsTab = ({
  user,
  userProfile,
  userType,
  setProcessing,
  setProcessingMsg,
  selectedTeams,
  setSelectedTeams,
  initialTeams,
}: {
  user: User | UserPending | undefined;
  userProfile: Profile | false | undefined;
  userType: UserTypes | undefined;
  setProcessing: (value: boolean | ((prev: boolean) => boolean)) => void;
  setProcessingMsg: (value: string | ((prev: string) => string)) => void;
  selectedTeams: TeamData[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<TeamData[]>>;
  initialTeams: TeamData[];
}) => {
  const app = useApp();
  const { contentServer } = useContext(RequestContext);
  const { data: allTeams } = useGetTeamsQuery();
  const { data: sitesDict } = useGetSitesDictQuery();
  const { roles, currentUser, featureAccess } = useUsers();
  const snackbar = useSnackbar();
  const [userRoles, setUserRoles] = useState<string[] | undefined>(undefined);
  const [defaultSite, setDefaultSite] = useState<string[]>([]);
  const [showBtns, setShowBtns] = useState<boolean>(false);
  const { isPageLoading, setIsPageLoading } = useContext(CommonPageContext);
  const [updateUser] = useUpdateUserMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [updateUserProfileTeam] = useUpdateUserProfileTeamMutation();
  const { data: userProfileTeams } = useGetUserProfileByIdQuery(userProfile ? userProfile.id : "", {
    skip: !userProfile,
  });

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const { data: objPerms, error: errorPermissions } = useSWR(
    user && user.id ? [ObjectPerissionSWRKeys.OBJECT_PERMISSIONS, "user", user.id] : null,
    () =>
      user && user.id
        ? contentServer.objectPermissionService.list([
            ["content_type", "site"],
            ["username", user.id],
          ])
        : []
  );

  const modifyAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER].update;
  }, [featureAccess]);

  const teamOptions = useMemo(() => {
    if (allTeams)
      return allTeams.map((teams) => {
        return { data: teams.id, displayVal: teams.name, usedInParent: false } as TeamData;
      });
    else return [] as TeamData[];
  }, [allTeams]);

  useEffect(() => {
    setUserRoles(user && user.roles ? Object.values(user.roles).map((role) => role) : undefined);
  }, [user]);

  useEffect(() => {
    const userTeams: TeamData[] = [];

    if (userType === UserTypes.Active || userType === UserTypes.Deactivated) {
      if (allTeams && userProfileTeams) {
        userProfileTeams.team.forEach((userTeam) => {
          const foundTeam = allTeams.find((team) => team.id === userTeam);
          if (foundTeam) userTeams.push({ data: foundTeam.id, displayVal: foundTeam.name } as TeamData);
        });
      }
    } else if (userType === UserTypes.Pending && user?.teams && allTeams) {
      user?.teams.forEach((userTeam) => {
        const foundTeam = allTeams.find((team) => team.id === userTeam);
        if (foundTeam) userTeams.push({ data: foundTeam.id, displayVal: foundTeam.name } as TeamData);
      });
    }
    setSelectedTeams(userTeams);
  }, [allTeams, setSelectedTeams, userProfileTeams, user?.teams, userType]);

  useEffect(() => {
    if (userProfile) {
      setDefaultSite(userProfile?.defaultSite.site ? [userProfile.defaultSite.site] : []);
    }
  }, [userProfile]);

  useEffect(() => {
    const objPermsLoading = objPerms === undefined && !errorPermissions;
    setIsPageLoading(objPermsLoading);
  }, [setIsPageLoading, objPerms, errorPermissions]);

  const initialUserSites: string[] = useMemo(() => {
    if (!objPerms || !sitesDict || !user) {
      return [] as string[];
    }

    if (userType === UserTypes.Pending && user.sites) {
      return user.sites;
    }

    const filteredObjPerms = objPerms.filter(
      (obj) =>
        obj.contentType === "site" &&
        obj.username === user.id &&
        obj.permissions?.view === PermissionValues.hasPermission
    );

    const userSites = Object.values(sitesDict)
      .filter((site) => filteredObjPerms?.find((perm) => site.id === perm.objectId))
      .map((site) => {
        return site.id;
      });
    return userSites;
  }, [objPerms, sitesDict, user, userType]);

  const initialUserRoles = useMemo(
    () => (user && user.roles ? Object.values(user.roles).map((role) => role) : []),
    [user]
  );

  const initialDefaultSite = useMemo(() => {
    if (userProfile && userProfile.defaultSite.site) {
      return [userProfile.defaultSite.site];
    }
    return [];
  }, [userProfile]);

  const [userSites, setUserSites] = useState<string[]>(initialUserSites);

  useEffect(() => {
    setUserSites((prev) => {
      if (!prev || prev.length === 0) {
        return initialUserSites;
      } else {
        return prev;
      }
    });
  }, [initialUserSites]);

  const userSitesMissing = useMemo(() => userSites.length === 0, [userSites]);
  const userRolesMissing = useMemo(() => userRoles === undefined || userRoles.length === 0, [userRoles]);
  const defaultMissing = useMemo(
    () => (defaultSite.length === 0 && userType !== UserTypes.Pending) || userSites.length == 0,
    [defaultSite, userType, userSites]
  );

  const displayUserSitesError = useMemo(() => {
    return !isPageLoading && userSitesMissing;
  }, [isPageLoading, userSitesMissing]);

  const displayuserRoleError = useMemo(() => {
    return !isPageLoading && userRolesMissing;
  }, [isPageLoading, userRolesMissing]);

  const displayDefaultError = useMemo(() => {
    return !isPageLoading && defaultMissing;
  }, [isPageLoading, defaultMissing]);

  const siteOptions = useMemo(() => {
    return sitesDict
      ? Object.values(sitesDict).map((site) => {
          return { id: site.id, label: site.name } as SearchOption;
        })
      : [{ id: "", label: "" }];
  }, [sitesDict]);

  const disabledIds = useMemo(() => {
    return Object.values(roles)
      .filter((role) => role.name === "admin" && user && currentUser && currentUser.id === user.id)
      .map((role: Role) => role.id);
  }, [roles, user, currentUser]);

  const disabledSiteIds = useMemo(() => {
    return user && currentUser && currentUser.id === user.id ? siteOptions.map((site) => site.id) : defaultSite;
  }, [currentUser, defaultSite, user, siteOptions]);

  const collectObjPerms = useCallback(() => {
    const addedPerms = userSites
      .map((siteId) => {
        const userObjPerm = objPerms?.find((perm) => {
          return perm.username === user?.id && perm.objectId === siteId;
        });
        if (userObjPerm) {
          return new ObjectPermissions({
            ...userObjPerm,
            permissions: {
              add: PermissionValues.hasPermission,
              change: PermissionValues.hasPermission,
              delete: PermissionValues.hasPermission,
              view: PermissionValues.hasPermission,
            },
          } as ObjectPermissions);
        } else {
          return {} as ObjectPermissions;
        }
      })
      .filter((perm) => perm.id);
    const removedPerms = initialUserSites
      .map((siteId) => {
        const userObjPerm = objPerms?.find((perm) => perm.username === user?.id && perm.objectId === siteId);
        if (userObjPerm) {
          if (!userSites.includes(siteId)) {
            return new ObjectPermissions({
              ...userObjPerm,
              permissions: {
                add: PermissionValues.noPermission,
                change: PermissionValues.noPermission,
                delete: PermissionValues.noPermission,
                view: PermissionValues.noPermission,
              },
            } as ObjectPermissions);
          } else {
            return {} as ObjectPermissions;
          }
        } else {
          return {} as ObjectPermissions;
        }
      })
      .filter((perm) => perm.id);

    return [...addedPerms, ...removedPerms];
  }, [initialUserSites, objPerms, user?.id, userSites]);

  const handleUpdateInvite = async (pendingUser: UserPending) => {
    try {
      const updatedPendingUser = { ...pendingUser };
      const updatedInv = {} as UserInvite;
      const rolesChanged =
        userRoles !== undefined &&
        (userRoles.length !== initialUserRoles.length ||
          userRoles.filter((roleId) => !initialUserRoles.includes(roleId)).length > 0);

      const sitesChanged =
        userSites !== undefined &&
        (userSites.length !== initialUserSites.length ||
          userSites.filter((siteId) => !initialUserSites.includes(siteId)).length > 0);

      const teamsChanged =
        selectedTeams.length !== initialTeams.length ||
        selectedTeams.filter((team) => !initialTeams.map((obj) => obj.data).includes(team.data)).length > 0;

      updatedInv.id = pendingUser.id;
      updatedInv.username = pendingUser.name;
      updatedInv.roles = pendingUser.roles;
      updatedInv.sites = pendingUser.sites;
      updatedInv.teams = pendingUser.teams;

      updatedPendingUser.teams = updatedInv.teams;

      if (app.isFeatureEnabled(TenantFeatureEnum.AssetV2)) {
        if (teamsChanged || rolesChanged) {
          if (rolesChanged && userRoles !== undefined) {
            const updatedRoles = [...userRoles];
            updatedInv.roles = updatedRoles;
            const updatedRoleList = [] as RoleIDList;
            userRoles.forEach((roleId) => updatedRoleList.push(roles[roleId].id));
            updatedPendingUser.roles = updatedRoleList;
          }
          if (teamsChanged) {
            const updatedTeams = [...selectedTeams];
            updatedInv.teams = updatedTeams.map((team) => team.data);
            updatedPendingUser.teams = updatedTeams.map((team) => team.data);
          }
          if (teamsChanged || rolesChanged) {
            mutate(
              [UserInviteSWRKeys.USER_INVITES],
              (prevInvs: UserInvite[] | undefined) => {
                return prevInvs ? [...prevInvs].map((inv) => (inv.id === updatedInv.id ? updatedInv : inv)) : [];
              },
              false
            );
            await contentServer.identityService.updateInvite(updatedPendingUser.id, updatedPendingUser);
          }
        }
      } else {
        if (rolesChanged || sitesChanged) {
          if (rolesChanged && userRoles !== undefined) {
            const updatedRoles = [...userRoles];
            updatedInv.roles = updatedRoles;
            const updatedRoleList = [] as RoleIDList;
            userRoles.forEach((roleId) => updatedRoleList.push(roles[roleId].id));
            updatedPendingUser.roles = updatedRoleList;
          }
          if (sitesChanged && userSites !== undefined) {
            const updatedSites = [...userSites];
            updatedInv.sites = updatedSites;
            updatedPendingUser.sites = updatedSites;
          }
        }
        if (sitesChanged || rolesChanged) {
          mutate(
            [UserInviteSWRKeys.USER_INVITES],
            (prevInvs: UserInvite[] | undefined) => {
              return prevInvs ? [...prevInvs].map((inv) => (inv.id === updatedInv.id ? updatedInv : inv)) : [];
            },
            false
          );
          await contentServer.identityService.updateInvite(updatedPendingUser.id, updatedPendingUser);
        }
      }
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Successfully saved profile changes.",
      });
    } catch (error) {
      console.error(error);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error saving profile changes.",
      });
    }
    setProcessing(false);
    setProcessingMsg("");
  };

  const handleUpdateUser = async (userWithProfile: User) => {
    if (userWithProfile) {
      try {
        const updatedUser = { ...userWithProfile };

        if (
          userRoles !== undefined &&
          (userRoles.length !== initialUserRoles.length ||
            userRoles.filter((roleId) => !initialUserRoles.includes(roleId)).length)
        ) {
          const updatedRoleDict = [] as RoleIDList;
          userRoles.forEach((roleId: string) => {
            updatedRoleDict.push(roles[roleId].id);
          });
          updatedUser.roles = updatedRoleDict;
          try {
            updateUser({ id: updatedUser.id, user: updatedUser, updatedRoles: true });
          } catch (error) {
            console.error(error);
          }
        }

        if (
          userSites.length !== initialUserSites.length ||
          userSites.filter((siteId) => !initialUserSites.includes(siteId)).length
        ) {
          const newPerms = collectObjPerms();
          mutate(
            [ObjectPerissionSWRKeys.OBJECT_PERMISSIONS, "user", updatedUser.id],
            (prevPerms: ObjectPermissions[]) => {
              const currPerms = [...prevPerms];
              return currPerms.map((p) => {
                const foundPerm = newPerms.findIndex((newPerm) => newPerm.id === p.id);
                return foundPerm ? { ...newPerms[foundPerm] } : p;
              });
            },
            false
          );
          for (let i = 0; i < newPerms.length; i++) {
            const perm = newPerms[i];
            await contentServer.objectPermissionService.patch(perm.id || "", perm);
          }
        }

        if (defaultSite !== initialDefaultSite) {
          const cloneUser = cloneDeep(updatedUser);
          cloneUser.userProfile.defaultSite.site = defaultSite[0];
          updateUserProfile({ id: updatedUser.id, profile: cloneUser.userProfile });
        }

        if (userProfileTeams && userProfileTeams.team) {
          const selectedTeamIds: string[] = [];
          for (const team of selectedTeams.filter((team) => team.data !== "ALL")) {
            selectedTeamIds.push(team.data);
          }
          const addTeams: string[] = selectedTeamIds.filter(
            (selectedId) => !userProfileTeams.team.includes(selectedId)
          );
          const removeTeams: string[] = userProfileTeams.team.filter((teamId) => !selectedTeamIds.includes(teamId));
          updateUserProfileTeam({ ...userProfileTeams, addTeam: addTeams, removeTeam: removeTeams } as IUserProfile);
        }

        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Successfully saved profile changes.",
        });
        setProcessing(false);
        setProcessingMsg("");
      } catch (error) {
        console.error(error);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error saving profile changes.",
        });
        setProcessing(false);
        setProcessingMsg("");
        await mutate(UsersSWRKeys.USERS_DISPLAY);
        await mutate([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS]);
        await mutate([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS, "user", userWithProfile.id]);
        await mutate(UsersSWRKeys.ROLES);
      }
    }
  };
  const handleCancel = useCallback(() => {
    setUserRoles(initialUserRoles);
    setDefaultSite(initialDefaultSite);
    setUserSites(initialUserSites);
    setSelectedTeams(initialTeams);
    setShowBtns(false);
  }, [initialDefaultSite, initialUserRoles, initialUserSites, initialTeams, setSelectedTeams]);

  const hasRequiredFields = useMemo(() => {
    return app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
      ? !userRolesMissing
      : !(userSitesMissing || defaultMissing || userRolesMissing);
  }, [userSitesMissing, defaultMissing, userRolesMissing, app]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            gap: 3,
            height: "100%",
            maxHeight: useWindowSize().height - (showBtns ? 250 : 190),
            overflowY: "scroll",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 0,
              gap: 1,
              minWidth: "300px",
              maxWidth: "500px",

              width: "100%",
            }}
          >
            <Typography variant="subtitle1">Roles</Typography>

            <FormControl
              required={true}
              sx={{ width: "100%" }}
              onClick={() => {
                return modifyAccess && setShowBtns(true);
              }}
            >
              <FilterPopup
                options={Object.values(roles).map((role) => {
                  return { id: role.id, label: role.name } as SearchOption;
                })}
                placeholderText={""}
                currentValue={userRoles ?? []}
                setCurrentValue={setUserRoles}
                disableClear={true}
                selectAll={true}
                selectAllText={"All Roles"}
                outlinedStyle={true}
                disabledIds={disabledIds}
                viewOnly={modifyAccess ? false : true}
              />
            </FormControl>
            <RequiredFieldMsg style={{ display: displayuserRoleError ? "" : "none" }} />
          </Box>

          {app.isFeatureEnabled(TenantFeatureEnum.AssetV2) ? (
            <Box
              sx={{
                display: app.isFeatureEnabled(TenantFeatureEnum.AssetV2) ? "flex" : "none",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 0,
                gap: 1,
                minWidth: "300px",
                maxWidth: "500px",
                width: "100%",
              }}
            >
              <Typography variant="subtitle1">Teams</Typography>
              <FormControl sx={{ width: "100%" }} onClick={() => modifyAccess && setShowBtns(true)}>
                {modifyAccess || selectedTeams.length !== 0 ? (
                  <TeamSelectionField
                    viewOnly={!modifyAccess}
                    options={teamOptions}
                    values={selectedTeams}
                    placeholderText={"Add Teams..."}
                    textOnly={true}
                    limitTags={1}
                    setSelectedTeams={setSelectedTeams}
                  />
                ) : (
                  <Typography variant="body1">No teams added (Admin only)</Typography>
                )}
              </FormControl>
            </Box>
          ) : (
            <>
              {userType && userType !== UserTypes.Pending && (
                <Box
                  style={{
                    display: app.isFeatureEnabled(TenantFeatureEnum.AssetV1) ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                    gap: 1,
                    minWidth: "300px",
                    maxWidth: "500px",
                    width: "100%",
                  }}
                >
                  <Typography variant="subtitle1">Default Site</Typography>

                  <FormControl required={true} sx={{ width: "100%" }} onClick={() => modifyAccess && setShowBtns(true)}>
                    <FilterPopup
                      options={userSites.map((siteId) => {
                        return { id: siteId, label: sitesDict ? sitesDict[siteId].name : "" };
                      })}
                      placeholderText={""}
                      currentValue={defaultSite}
                      setCurrentValue={setDefaultSite}
                      singleSelect={true}
                      disableClear={true}
                      selectAll={false}
                      selectAllText={""}
                      outlinedStyle={true}
                      viewOnly={modifyAccess ? false : true}
                    />
                  </FormControl>
                  <RequiredFieldMsg style={{ display: displayDefaultError ? "" : "none" }} />
                </Box>
              )}
              <Box
                style={{
                  display: app.isFeatureEnabled(TenantFeatureEnum.AssetV1) ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: 0,
                  gap: 1,
                  minWidth: "300px",
                  maxWidth: "500px",
                  width: "100%",
                }}
              >
                <Typography variant="subtitle1">Sites</Typography>

                <FormControl required={true} sx={{ width: "100%" }} onClick={() => modifyAccess && setShowBtns(true)}>
                  <FilterPopup
                    options={siteOptions}
                    placeholderText={""}
                    currentValue={userSites}
                    setCurrentValue={setUserSites}
                    disableClear={true}
                    selectAll={currentUser && user && currentUser.id === user.id ? false : true}
                    selectAllText={"All Sites"}
                    outlinedStyle={true}
                    disabledIds={disabledSiteIds}
                    viewOnly={modifyAccess ? false : true}
                  />
                </FormControl>
                <RequiredFieldMsg style={{ display: displayUserSitesError ? "" : "none" }} />
              </Box>
            </>
          )}
        </Box>
        {showBtns && (
          <SettingsBottomBar show={true} hasRequiredFields={hasRequiredFields}>
            <>
              <BaseButton variant="outlined" onClick={handleCancel}>
                Cancel
              </BaseButton>
              <BaseButton
                variant="contained"
                onClick={async () => {
                  const hasFieldMissingPending = app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
                    ? userRolesMissing
                    : userSitesMissing || userRolesMissing;
                  if (user && !userProfile && userType === UserTypes.Pending) {
                    if (!hasFieldMissingPending) {
                      setProcessingMsg("Updating invite...");
                      setProcessing(true);
                      await handleUpdateInvite(user);
                    }
                  } else if (user) {
                    const userWithProfile = hasProfile(user);
                    const hasFieldMissingActive = app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
                      ? hasFieldMissingPending
                      : hasFieldMissingPending || defaultMissing;
                    if (userWithProfile && !hasFieldMissingActive) {
                      setProcessingMsg("Updating profile...");
                      setProcessing(true);

                      await handleUpdateUser(userWithProfile);
                    }
                  }

                  setShowBtns(false);
                }}
              >
                Save Changes
              </BaseButton>
            </>
          </SettingsBottomBar>
        )}
      </Box>
    </>
  );
};

export default ProfilePemissionsTab;
