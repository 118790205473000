import * as msal from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import axios from "axios";
import Config from "Config";
import React, { useState, useEffect, useContext, createContext, useMemo } from "react";
import { useGetUserPermissionsQuery } from "redux/api/userApi";
import { getToken, setToken, setAccount as setUserAccount } from "redux/features/authSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";

import { GetUserPermissions } from "./usePermission";
import { UserInfo } from "services/AzureAD";
import { editProfileRequest, inviteRequest, loginRequest } from "services/AzureAD/authConfig";
import { storeCurrentPath } from "services/AzureAD/LocalRedirectUrlStorage";
import { FeatureAccessDict, PermissionDict } from "services/ContentServer/Identity";

const LOGIN_CACHE_KEY = "FORCELOGIN";
const CACHE_KEY = "USERINVITE";
export const EDITED_PROFILE_CACHE_KEY = "EDITEDPROFILE";
const CACHE_EXPIRY = 600000;
const MSAL_ERROR_DICT: { [key: string]: string } = {
  "No tenants found": "/accountnotfound",
  "You are already registered": "/userexists",
  "The provided id_token_hint parameter is expired": "/accountnotfound",
};

interface IContextProps {
  user: UserInfo | null;
  account: msal.AccountInfo | undefined;
  userPermissions: PermissionDict | undefined;
  userPermError: FetchBaseQueryError | SerializedError | undefined;
  featureAccess: FeatureAccessDict;
  setupComplete: boolean;
  readyToFetchData: boolean;
  setSetupComplete: (value: boolean | ((prev: boolean) => boolean)) => void;
  getAccounts: (workAccounts: boolean) => msal.AccountInfo[];
  login: (account?: msal.AccountInfo) => void;
  loginWorkAccount: () => void;
  editProfile: () => void;
  invite: (extras: string, inviteId: string) => void;
  switchAccount: (forceLogin: boolean) => void;
  logout: () => void;
}

const authContext = createContext({
  user: null,
  account: undefined,
  userPermissions: {},
  userPermError: undefined,
  featureAccess: {},
  setupComplete: false,
  readyToFetchData: false,
  setSetupComplete: (value: boolean) => {},
  getAccounts: (workAccounts: boolean) => {},
  login: (Account?: msal.AccountInfo) => {},
  loginWorkAccount: () => {},
  editProfile: () => {},
  invite: (extras: string, inviteId: string) => {},
  switchAccount: (forceLogin = false) => {},
  logout: () => {},
} as IContextProps);

export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const { instance } = useMsal();
  const token = useAppSelector(getToken);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [account, setAccount] = useState<msal.AccountInfo | undefined>(undefined);
  const [setupComplete, setSetupComplete] = useState(false);
  const dispatch = useAppDispatch();
  const { data: userPermissions, error: userPermError } = useGetUserPermissionsQuery(user?.id ?? "", {
    skip: user === null || token === "" || !setupComplete,
  });
  const featureAccess = GetUserPermissions(userPermissions);

  const readyToFetchData = useMemo(
    () => user !== null && setupComplete && userPermissions !== undefined,
    [user, setupComplete, userPermissions]
  );

  const getIDP = async () => {
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    const axiosInstance = axios.create();
    try {
      const resp = await axiosInstance.get(Config.BACKEND_BASE_URL + "/idps/" + subdomain);
      return resp.data;
    } catch (e) {
      return "local";
    }
  };

  const getAccounts = (workAccounts: boolean) => {
    const accounts = instance.getAllAccounts();
    const addedAccounts: string[] = [];
    const filteredAccounts = accounts.filter((account: { username: string; localAccountId: string }) => {
      if (account.localAccountId && !addedAccounts.includes(account.localAccountId)) {
        if ((!account.username && workAccounts) || account.username) {
          addedAccounts.push(account.localAccountId);
          return true;
        }
      }
      return false;
    });
    return filteredAccounts;
  };

  const silentTokenLogin = async (account: msal.AccountInfo) => {
    instance
      .acquireTokenSilent({
        scopes: loginRequest.scopes,
        redirectUri: loginRequest.redirectUri,
        account: account,
      })
      .then((resp: { account: any; accessToken: string }) => {
        dispatch(setToken(resp.accessToken));
        if (resp.account) {
          setAccount(resp.account);
          dispatch(setUserAccount(resp.account));
          setSetupComplete(true);
        }
      })
      .catch(() => {
        instance.loginRedirect({
          ...loginRequest,
          account: account,
          prompt: "login",
          state: "redirect=" + window.location.origin,
          extraQueryParameters: {
            domain_hint: account.idTokenClaims?.idp as string,
          },
        });
      });
  };

  const login = async (account?: msal.AccountInfo) => {
    const forceLogin = localStorage.getItem(LOGIN_CACHE_KEY);
    localStorage.removeItem(LOGIN_CACHE_KEY);

    if (account && !forceLogin) {
      silentTokenLogin(account);
    } else {
      instance.loginRedirect({
        ...loginRequest,
        account: account,
        prompt: "login",
        state: "redirect=" + window.location.origin,
        extraQueryParameters: {
          domain_hint: await getIDP(),
        },
      });
    }
  };

  const loginWorkAccount = (account?: msal.AccountInfo) => {
    if (account) {
      const idp = account.idTokenClaims?.idp as string;
      instance.loginRedirect({
        ...loginRequest,
        extraQueryParameters: {
          domain_hint: idp,
        },
      });
    } else {
      login();
    }
  };

  const editProfile = async () => {
    storeCurrentPath();
    localStorage.setItem(
      EDITED_PROFILE_CACHE_KEY,
      window.location.origin + window.location.pathname + window.location.search + window.location.hash
    );
    instance.loginRedirect({
      ...editProfileRequest,
      account: account,
      extraQueryParameters: {
        domain_hint: await getIDP(),
      },
      state: "edit&redirect=" + window.location.origin,
    });
  };

  const invite = async (extras: string, inviteId: string) => {
    const axiosInstance = axios.create();
    try {
      await axiosInstance.get(Config.BACKEND_BASE_URL + "/api/validateinvite/" + inviteId + "/");
    } catch {
      window.location.href = "/invalidinvite";
    }

    const now = new Date();
    const inv = {
      flags: extras,
      expiry: now.getTime() + CACHE_EXPIRY,
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(inv));
    instance.loginRedirect({
      ...inviteRequest,
      state: "redirect=" + window.location.origin,
      extraQueryParameters: {
        id_token_hint: extras,
        domain_hint: await getIDP(),
      },
    });
  };

  const switchAccount = (forceLogin = false) => {
    localStorage.setItem(LOGIN_CACHE_KEY, forceLogin.toString());
    setAccount(undefined);
    setUser(null);
    window.location.pathname = "login";
  };

  const logout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (account) {
      setUser({ id: account.localAccountId, tenantId: (account.idTokenClaims?.appTenantId as string) || "" });
    }
  }, [account]);

  instance
    .handleRedirectPromise()
    .then((resp: msal.AuthenticationResult | null) => {
      try {
        if (window.location.hash) {
          const hash = window.location.hash;
          const redirectUri = decodeURIComponent(hash.split("redirect%3d")[1].split("&")[0]);
          if (redirectUri !== window.location.origin) {
            window.location.href = redirectUri + "/.aad-callback" + window.location.hash;
          }
        }
      } catch (error) {
        console.error("Redirect Failed: " + error);
      }

      if (resp?.account) {
        setAccount(resp.account);
        dispatch(setUserAccount(resp.account));
      }
    })
    .catch((error: msal.ServerError) => {
      const redirectUrl = Object.entries(MSAL_ERROR_DICT).find(([errorMessage, redirect]) => {
        return error.errorMessage.includes(errorMessage);
      })?.[1];
      if (redirectUrl) window.location.href = redirectUrl;

      if (error.errorCode === "access_denied") {
        window.location.replace(localStorage.getItem(EDITED_PROFILE_CACHE_KEY) || window.location.origin);
      }
    });

  // Return the user object and auth methods
  return {
    user,
    account,
    userPermissions,
    userPermError,
    featureAccess,
    setupComplete,
    readyToFetchData,
    setSetupComplete,
    getAccounts,
    login,
    loginWorkAccount,
    invite,
    switchAccount,
    logout,
    editProfile,
  };
}
