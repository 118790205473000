import AddIcon from "@mui/icons-material/Add";
import BusinessOutlined from "@mui/icons-material/BusinessOutlined";
import MeetingRoomOutlined from "@mui/icons-material/MeetingRoomOutlined";
import { Grid, Box, Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { Skeleton, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RoleUsersIcon from "icons/RoleUsersIcon";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { SingleSite, useGetSitesDictQuery } from "redux/api/appApi";
import useSWR from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import SitesNewSiteDialog from "./SitesNewSiteDialog";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import { EnhancedTableHead } from "components/table/EnhancedTable";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRefResizeObserver } from "hooks/useResizeObserver";
import { useRouter } from "hooks/useRouter";
import useUsers from "hooks/useUsers";
import { AssetSWRKeys } from "services/ContentServer/Audit/services/AssetService";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { SnapshotSWRKeys } from "services/ContentServer/Audit/services/SnapshotService";
import { ObjectPermissions, PermissionValues } from "services/ContentServer/Audit/serviceTypes/ObjectPermissions";
import { Profile, User } from "services/ContentServer/Identity";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { StableSort, GetComparatorFcn, SortDirection } from "utils/SortRowsUtils";
import { matchSorter } from "utils/SortRowsUtils";
import { percentToDecimal } from "utils/StringUtils";

export const getSiteUsers = (siteId: string, permissions: ObjectPermissions[] | undefined, users: User[]) => {
  if (permissions) {
    const allowedUserIds = permissions
      .filter((perm) => perm.objectId === siteId && perm.permissions?.view === PermissionValues.hasPermission)
      .map((perm) => perm.username);
    return Object.values(users).filter((user) => allowedUserIds.includes(user.id));
  } else {
    return [];
  }
};

interface SiteRowData {
  id: string;
  name: string;
  keyContact: string;
  facilities: number;
  assets: number;
  users: User[];
  keyContactObj?: Profile;
}

const SKELETON_ROWS = 8;
const headCells: { id: keyof SiteRowData; label: string }[] = [
  { id: "name", label: "Site" },
  { id: "keyContact", label: "Key Contact" },
  { id: "facilities", label: "Facilities" },
  { id: "assets", label: "Assets" },
  { id: "users", label: "Users" },
];

const cellWidths = new Map<string, string>([
  ["name", "26.61%"],
  ["keyContact", "51.39%"],
  ["facilities", "7.33%"],
  ["assets", "7.33%"],
  ["users", "7.33%"],
]);

const SitesPage = ({ setSelected }: { setSelected: (value: SettingTab) => void }) => {
  const theme = useTheme();
  const { contentServer } = useContext(RequestContext);

  const { data: sitesDict } = useGetSitesDictQuery();
  const { users, error, loaded, profiles } = useUsers();
  const [order, setOrder] = useState<SortDirection | undefined>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState("None");
  const [query, setQuery] = useState<string>("");
  const [newSiteDialogOpen, setNewSiteDialogOpen] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setSelected(SettingTab.SITES));
  }, [setSelected]);

  const { data: snaps } = useSWR([SnapshotSWRKeys.SNAPSHOTS, "facilities"], () => {
    return contentServer.snapshotService.list(undefined, ["id", "facility_id", "asset"]);
  });

  const { data: assets, error: assetError } = useSWR([AssetSWRKeys.ASSETS, "site", "all_assets"], () =>
    contentServer.assetService.list([["is_template", false.toString()]], ["id", "site_id"])
  );

  const { data: objPermissions } = useSWR([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS], () =>
    contentServer.objectPermissionService.list([["content_type", "site"]])
  );

  const [tableSize, setTableSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { ref: tableRef } = useRefResizeObserver(setTableSize);

  const { featureAccess } = useUsers();
  const { history } = useRouter();
  const siteAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.SITE];
  }, [featureAccess]);

  const navigateToEditPage = useCallback(
    (siteId: string) => {
      history.push(`${SettingRoutesDict.get(SettingTab.SITE_EDIT)?.path}?siteId=${siteId}`);
    },
    [history]
  );

  const handleRequestSort = useCallback(
    (event: any, property: string) => {
      const isAsc = orderBy === property && order === SortDirection.ASC;
      setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const getSiteFacilities = useCallback(
    (siteId: string) => {
      if (snaps) {
        const siteSnaps = snaps.filter((snap) => snap.siteId === siteId);
        const hMap = new Map<string, boolean>();
        if (siteSnaps) {
          Object.values(siteSnaps).forEach((snap) => {
            if (snap && snap.facility) {
              if (hMap.has(snap.facility)) {
                //pass
              } else {
                hMap.set(snap.facility, true);
              }
            }
          });
        }
        return hMap.size;
      } else {
        return "-";
      }
    },
    [snaps]
  );

  const getSiteAssets = useCallback(
    (siteId: string) => {
      if (assets) {
        return assets.filter((asset) => asset.site === siteId).length;
      } else if (assetError) {
        ("-");
      } else {
        return "Loading";
      }
    },
    [assets, assetError]
  );

  const renderSkeletonRow = useCallback(() => {
    const cells = headCells.map((headCell, idx) => (
      <TableCell
        id={`cell-col-${idx}`}
        key={idx}
        align="left"
        style={{
          width: cellWidths.get(headCell.id),
          height: "20px",
          border: 0,
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingLeft: 0,
        }}
      >
        <Skeleton key={idx} sx={{ borderRadius: "2px", width: "100%", height: "30px" }} />
      </TableCell>
    ));
    return cells;
  }, []);

  const siteRows = useMemo(() => {
    return sitesDict
      ? Object.values(sitesDict).map((site: SingleSite) => {
          return {
            id: site.id,
            name: site.name,
            keyContact: profiles[site.keyContact]?.displayName || "",
            facilities: getSiteFacilities(site.id),
            assets: getSiteAssets(site.id),
            users: getSiteUsers(site.id, objPermissions, users),
            address: site.address,
            country: site.country,
            region: site.region,
            zipPostalCode: site.zipPostalCode,
            company: site.company,
          } as SiteRowData;
        })
      : ([] as SiteRowData[]);
  }, [sitesDict, profiles, getSiteFacilities, getSiteAssets, objPermissions, users]);

  const applyFilters = useCallback(
    (rows: SiteRowData[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const tableData = useMemo(() => {
    return applyFilters(siteRows);
  }, [applyFilters, siteRows]);

  const tableRows = useMemo(() => {
    return StableSort(tableData, GetComparatorFcn(order, orderBy, false));
  }, [order, orderBy, tableData]);

  const tableRow = useCallback(
    (rowIdx, row) => {
      return (
        <>
          {headCells.map((headCell, idx) => {
            const widthPercent = cellWidths.get(headCell.id);
            return (
              <TableCell
                id={`cell-col-${idx}`}
                key={idx}
                align="left"
                padding="normal"
                style={{
                  width: tableSize.width * (widthPercent ? percentToDecimal(widthPercent) : 0),
                  border: 0,
                  height: "4.9vh",
                  padding: "0px 16px 0px 0px",
                }}
                onClick={() => {
                  if (siteAccess.update) {
                    const fullDataRow = { ...row };
                    fullDataRow.keyContactObj = Object.values(profiles).find(
                      (profile) => profile.displayName === row.keyContact
                    );
                    navigateToEditPage(fullDataRow.id);
                  }
                }}
              >
                {headCell.id === "facilities" || headCell.id === "assets" || headCell.id === "users" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "40px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "140%",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {headCell.id === "facilities" && (
                      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <BusinessOutlined />
                        {row[headCell.id]}
                      </div>
                    )}
                    {headCell.id === "assets" && (
                      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <MeetingRoomOutlined />
                        {row[headCell.id]}
                      </div>
                    )}
                    {headCell.id === "users" && (
                      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <RoleUsersIcon />
                        {row[headCell.id].length}
                      </div>
                    )}
                  </div>
                ) : (
                  <Typography variant="body1">{row[headCell.id]}</Typography>
                )}
              </TableCell>
            );
          })}
        </>
      );
    },
    [navigateToEditPage, profiles, siteAccess.update, tableSize.width, theme.palette.primary.main]
  );

  return (
    <>
      <main style={{ backgroundColor: "white", width: "80%" }}>
        <SitesNewSiteDialog open={newSiteDialogOpen} setOpen={setNewSiteDialogOpen} />
        <Grid container direction="row" spacing={3} style={{ padding: "48px 0px 0px 80px" }}>
          <Grid item xs={12}>
            <Typography variant="h1">Sites</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "flex-start", padding: "0px", gap: "24px" }}>
              <SearchBar placeholderText={"Search sites..."} query={query} setQuery={setQuery} />
              {siteAccess.add && (
                <BaseButton
                  variant={"outlined"}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setNewSiteDialogOpen(true);
                  }}
                  style={{ maxHeight: "36px" }}
                >
                  New Site
                </BaseButton>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              ref={tableRef}
              sx={{
                height: "70vh",
                width: "100%",
                "&::-webkit-scrollbar": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#a2a4a6",
                  height: "12vh",
                  maxHeight: "12vh",
                  minHeight: "12vh",
                  width: "8px",
                  borderRadius: "100px",
                },
              }}
            >
              {tableData.length > 0 ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <TableVirtuoso
                    data={tableRows}
                    style={{ height: "100%" }}
                    itemContent={(index, rowData) => tableRow(index, rowData)}
                    fixedHeaderContent={() => (
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        cellWidths={cellWidths}
                        width={tableSize.width}
                      />
                    )}
                  />
                </div>
              ) : (
                <Table size="small" style={{ maxWidth: "100%", width: "100%", height: tableSize.height }} stickyHeader>
                  <TableBody style={{ width: "100%", paddingTop: "40px" }}>
                    {!loaded &&
                      Array(SKELETON_ROWS)
                        .fill(1)
                        .map((ele, idx) => {
                          return <TableRow key={idx}>{renderSkeletonRow()}</TableRow>;
                        })}
                  </TableBody>
                </Table>
              )}
              {(error || tableData.length == 0) && (
                <Box
                  style={{
                    height: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: " 16px 0px",
                    gap: "40px",
                  }}
                >
                  {error ? (
                    <Typography variant="body1" style={{ paddingTop: "16px" }}>
                      Error fetching sites.
                    </Typography>
                  ) : (
                    <Typography variant="body1" style={{ paddingTop: "16px" }}>
                      {sitesDict && Object.values(sitesDict).length === 0
                        ? "No sites loaded."
                        : "No sites matched this search."}
                    </Typography>
                  )}
                </Box>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default SitesPage;
