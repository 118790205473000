import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Box, useTheme, Button } from "@mui/material";
import { Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState, useCallback, useReducer } from "react";
import { Link } from "react-router-dom";
import { TenantFeatureEnum } from "redux/api/types";
import { useUpdateUserMutation, useGetRoleByIdQuery } from "redux/api/userApi";
import useApp from "redux/hooks/useApp";
import { v4 as uuidv4 } from "uuid";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import UsersTab from "../Teams/UsersTab";
import RoleInfoTab from "./RoleInfoTab";
import RolePermissionsTab from "./RolePermissionsTab";
import { initialRoleState, RoleActionType, roleReducer, RoleState } from "./roleReducer";
import RoleUsersTab from "./RoleUsersTab";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import portalRoutes from "components/Routes";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { ADMIN_ROLE, MEMBER_ROLE, Role, RoleIDList, User } from "services/ContentServer/Identity";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { duplicateSuffixes, toTitleCase } from "utils/StringUtils";

const RoleEditPage = () => {
  const app = useApp();
  const { getStringQuery, history } = useRouter();
  const snackbar = useSnackbar();
  const { contentServer } = useContext(RequestContext);
  const { users, roles, rolesLoaded, rolesError, usersLoading, refetchUsers, refetchRoles } = useUsers();
  const [tab, setTab] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [processing, setProcessing] = useState(false);
  const [processingMsg, setProcessingMsg] = useState("Loading");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [roleState, dispatchRoleState] = useReducer(roleReducer, initialRoleState);
  const [role, setRole] = useState<RoleState>(initialRoleState);
  const [runOnce, setRunOnce] = useState<number>(0);
  const [userRowCount, setUserRowCount] = useState<number>(0);
  const [updateUser] = useUpdateUserMutation();
  const { data: roleData, refetch: refetchRole } = useGetRoleByIdQuery(selectedRoleId || "", {
    skip: !selectedRoleId,
  });

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setSelectedRoleId(getStringQuery("roleId") || "");
  }, [getStringQuery]);

  const resetVars = useCallback(
    (data: RoleState) => {
      const tmp: User[] = users.filter((user) => user.roles.some((userRoleId) => userRoleId === data.id));
      setUserRowCount(tmp.length);
      setRole({ ...data, users: [...tmp] });
      dispatchRoleState({ ...data, users: [...tmp], type: RoleActionType.UPDATE_ROLE });
    },
    [users]
  );

  useEffect(() => {
    if (users.length !== 0 && runOnce === 0 && roleData && roleData.id !== undefined) {
      resetVars({
        ...initialRoleState,
        id: roleData.id,
        name: roleData.name,
        desc: roleData.description,
        permissions: roleData.permissions,
      });
      setProcessing(false);
      setRunOnce(1);
    }
  }, [runOnce, roleData, users, resetVars]);

  useEffect(() => {
    const added: User[] = [];
    const removed: User[] = [];

    roleState.users.forEach((user) => {
      if (!role.users.includes(user)) {
        added.push(user);
      }
    });
    role.users.forEach((user) => {
      if (!roleState.users.includes(user)) {
        removed.push(user);
      }
    });
    dispatchRoleState({ addedUsers: added, removedUsers: removed, type: RoleActionType.UPDATE_ADDED_REMOVED_USERS });
  }, [role, roleState.users]);

  const didEdit = useMemo(() => {
    if (roleState.users && roleState.id) {
      return (
        role.name !== roleState.name ||
        role.desc !== roleState.desc ||
        roleState.addedUsers.length > 0 ||
        roleState.removedUsers.length > 0
      );
    }
    return true;
  }, [roleState, role]);

  const isNew = useMemo(() => roleState.id === "", [roleState]);

  const handleDiscardChanges = useCallback(() => {
    dispatchRoleState({
      role: { id: role.id, name: role.name, description: role.desc, logo: "", permissions: role.permissions },
      users: role.users,
      type: RoleActionType.RESET_ALL,
    });

    if (getStringQuery("roleId") === "") {
      history.push(`${portalRoutes.systemSettingsPage.path}/roles`);
    }
  }, [role, getStringQuery, history]);

  const handleInfoChange = useCallback((role: RoleState) => {
    dispatchRoleState({ ...role, type: RoleActionType.UPDATE_ROLE });
  }, []);

  const onSuccessResponse = useCallback(
    (newName: string) => {
      dispatchRoleState({ addedUsers: [], removedUsers: [], type: RoleActionType.UPDATE_ADDED_REMOVED_USERS });
      dispatchRoleState({ name: newName, type: RoleActionType.UPDATE_ROLE_NAME });
      setRole({ ...roleState, name: newName });
      setProcessing(false);
      setProcessingMsg("");
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Role changes successfully submitted.",
      });
    },
    [snackbar, roleState]
  );

  const onErrorResponse = useCallback(() => {
    setProcessing(false);
    setProcessingMsg("");
    dispatchRoleState({ addedUsers: [], removedUsers: [], type: RoleActionType.UPDATE_ADDED_REMOVED_USERS });

    snackbar.dispatch({
      type: SnackbarActionType.OPEN,
      message: "Error submitting role changes.",
    });
  }, [snackbar]);

  const handleUpdateRole = useCallback(async () => {
    setProcessingMsg("Saving role...");
    setProcessing(true);
    if (!isNew && roleState.id === "") {
      console.error("Error updating role. Role is undefined.");
      onErrorResponse();
      return;
    }
    if (roleState.addedUsers.length === 0 && roleState.removedUsers.length === 0) {
      let permissions = {};
      if (roleData?.permissions) {
        permissions = roleData?.permissions;
      }
      let newName = roleState.name;
      const suffixes = duplicateSuffixes(Object.values(roles), {
        id: roleState.id,
        name: roleState.name,
        description: "",
        logo: "",
        permissions: {},
      });
      if (suffixes.length > 0 && suffixes[0] != 0) {
        newName += ` (${suffixes[0]})`;
      }
      const updatedRole: Role = {
        id: roleState.id !== "" ? roleState.id : uuidv4(),
        name: newName,
        description: roleState.desc,
        permissions: permissions,
        logo: "",
      };
      try {
        if (roleState.id !== "") {
          await contentServer.identityService.updateRole(updatedRole);
        } else {
          await contentServer.identityService.createRole(updatedRole);
          const roleEditRoute = SettingRoutesDict.get(SettingTab.ROLE_EDIT);
          if (roleEditRoute) {
            history.replace(`${roleEditRoute.path}?roleId=${updatedRole.id}`);
          }
        }
        refetchRoles();
        refetchRole();
        refetchUsers();
        onSuccessResponse(newName);
      } catch (error) {
        console.error(error);
        onErrorResponse();
      }
    } else {
      try {
        const promisesRemoved = roleState.removedUsers.map((user) => {
          const updatedUser = { ...user };
          const updatedRoleList: RoleIDList = [];
          Object.values(user.roles)
            .filter((userRoleId) => userRoleId !== role.id)
            .forEach((userRoleId) => {
              updatedRoleList.push(userRoleId);
            });
          updatedUser.roles = updatedRoleList;
          return updateUser({ id: user.id, user: updatedUser, updatedRoles: true }).unwrap();
        });
        const promisesAdded = roleState.addedUsers.map((user) => {
          const updatedUser = { ...user };
          const updatedRoleList: RoleIDList = [...user.roles];
          if (!updatedRoleList.find((id) => id === role.id)) {
            updatedRoleList.push(role.id);
          }
          updatedUser.roles = updatedRoleList;
          return updateUser({ id: user.id, user: updatedUser, updatedRoles: true }).unwrap();
        });
        await Promise.allSettled(promisesAdded);
        await Promise.allSettled(promisesRemoved);
        onSuccessResponse(role.name);
        refetchRoles();
        refetchRole();
        refetchUsers();
      } catch (err) {
        console.error(err);
        onErrorResponse();
      }
    }
  }, [
    isNew,
    updateUser,
    contentServer.identityService,
    history,
    roleData?.permissions,
    roles,
    setProcessing,
    setProcessingMsg,
    roleState,
    onSuccessResponse,
    onErrorResponse,
    role,
    refetchRoles,
    refetchRole,
    refetchUsers,
  ]);

  const handleDelete = useCallback(async () => {
    if (roleState.id === "") {
      console.error("Error deleting role. Role is undefined");
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error deleting role.",
      });
      return;
    }
    const rolesRoute = SettingRoutesDict.get(SettingTab.ROLES);

    setProcessingMsg("Deleting role...");
    setProcessing(true);

    try {
      await contentServer.identityService.deleteRole(roleState.id);
      refetchRoles();
      refetchUsers();
      if (rolesRoute) {
        history.push(rolesRoute.path);
      }
      dispatchRoleState({ ...initialRoleState, type: RoleActionType.RESET_ALL });
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Role successfully deleted.",
      });
    } catch (error) {
      console.error(error);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error deleting role.",
      });
    }
    setProcessing(false);
    setProcessingMsg("");
  }, [
    roleState,
    contentServer.identityService,
    setProcessing,
    setProcessingMsg,
    history,
    snackbar,
    refetchRoles,
    refetchUsers,
  ]);

  const handleUsersChange = useCallback((users: User[]) => {
    dispatchRoleState({ users: users, type: RoleActionType.UPDATE_USERS });
  }, []);

  const InfoTabComponent = useMemo(
    () => (
      <RoleInfoTab
        role={roleState}
        onChange={handleInfoChange}
        onDelete={() => {
          setDeleteConfirmOpen(true);
        }}
        didModify={isNew || didEdit}
      />
    ),
    [roleState, handleInfoChange, isNew, didEdit]
  );

  const PermissionTabComponent = useMemo(() => {
    return (
      <RolePermissionsTab key={1} role={roleData} setProcessing={setProcessing} setProcessingMsg={setProcessingMsg} />
    );
  }, [roleData]);

  const UsersTabComponent = useMemo(() => {
    if (app.isFeatureEnabled(TenantFeatureEnum.AssetV2)) {
      return (
        <UsersTab
          itemLabel={"role"}
          itemsLabel={"roles"}
          itemName={roleState.name}
          selectedUsers={roleState.users}
          onChange={handleUsersChange}
          listModified={isNew || didEdit}
        />
      );
    } else {
      return (
        <RoleUsersTab
          key={2}
          role={roleState.id !== "" ? roleData : undefined}
          setUserRowCount={setUserRowCount}
          setProcessing={setProcessing}
          setProcessingMsg={setProcessingMsg}
        />
      );
    }
  }, [roleState, roleData, app, handleUsersChange, isNew, didEdit]);

  const TabComponents = useMemo(
    () => (!isNew ? [InfoTabComponent, PermissionTabComponent, UsersTabComponent] : [InfoTabComponent]),
    [InfoTabComponent, PermissionTabComponent, UsersTabComponent, isNew]
  );

  const userCount = useMemo(() => {
    if (app.isFeatureEnabled(TenantFeatureEnum.AssetV2)) {
      return roleState.users.length;
    } else {
      return userRowCount;
    }
  }, [roleState.users, app, userRowCount]);

  return (
    <>
      <LoadingDialog processing={usersLoading || !(rolesError || rolesLoaded) || processing} msg={processingMsg} />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            height: "auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            pt: 3,
            pb: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            gap: 0.5,
          }}
        >
          <Link to={SettingRoutesDict.get(SettingTab.ROLES)?.path ?? ""} style={{ textDecoration: "none" }}>
            <Typography variant="h1" sx={{ "&:hover": { textDecoration: "underline" } }}>
              Roles
            </Typography>
          </Link>
          <ChevronRightOutlinedIcon style={{ color: theme.palette.primary.main }} />
          <Typography variant="h1" style={{ paddingBottom: 0 }}>
            {roleState.name === ADMIN_ROLE || roleState.name === MEMBER_ROLE
              ? toTitleCase(roleState.name)
              : roleState.name}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingBottom: 0,
            height: "auto",
            width: "100%",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ borderBottom: "solid 1px #e1e2e4", width: "100%", height: "100%" }}>
            <HeaderTabs
              tab={tab}
              setTab={setTab}
              headers={!isNew ? ["Information", "Permissions", `Users (${userCount})`] : ["Information"]}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          {TabComponents[tab]}
        </Box>

        {(isNew || didEdit) && tab !== 1 && (
          <SettingsBottomBar show={true} hasRequiredFields={roleState.name.length > 0}>
            <>
              <Button variant="outlined" onClick={handleDiscardChanges}>
                Cancel
              </Button>
              <Button variant="contained" disabled={false} onClick={async () => await handleUpdateRole()}>
                {getStringQuery("roleId") === "" ? "Create Role" : "Save Changes"}
              </Button>
            </>
          </SettingsBottomBar>
        )}
      </Box>

      <ConfirmationDialog
        useOpen={{ state: deleteConfirmOpen, setState: setDeleteConfirmOpen }}
        onConfirm={() => {
          handleDelete();
        }}
        dialogText={`Are you sure you want to delete this role? All users with this role will no longer have these permissions.`}
        title={"Delete Role"}
        confirmText={"Delete"}
      />
    </>
  );
};

export default RoleEditPage;
