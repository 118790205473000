import { Add, DeleteOutline, EditOutlined, ForwardToInboxSharp, SourceOutlined } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import CopyPageIcon from "icons/CopyPageIcon";
import { intersection } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { useGetSitesDictQuery } from "redux/api/appApi";
import { TenantFeatureEnum } from "redux/api/types";
import { useLazyGetUsersByPageQuery, UserParam, useUpdateUserMutation } from "redux/api/userApi";
import useApp from "redux/hooks/useApp";
import useSWR, { mutate } from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import ResendInviteDialog from "./ResendInviteDialog";
import UserInviteDialog from "./UserInviteDialog";
import { ARRowCell } from "components/ARTable";
import ContextButton, { MenuOption } from "components/DalmatianDesignComponents/ContextButton";
import DropdownFilter from "components/DalmatianDesignComponents/DropdownFilter";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { ExpandDropdown } from "components/DalmatianDesignComponents/ExpandDropdown";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import PaginationSearchBar from "components/DalmatianDesignComponents/PaginationSearchBar";
import { ALL_RESULTS, SearchOption } from "components/DalmatianDesignComponents/SearchBar";
import AccountDialog from "components/Dialog/AccountDialog";
import ExportUsersDialog from "components/Dialog/ExportUsersDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import UserLogDialog from "components/Dialog/UserLogDialog";
import MoreMenu from "components/menu/MoreMenu";
import { EnhancedTableHead } from "components/table/EnhancedTable";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRefResizeObserver } from "hooks/useResizeObserver";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useTeams from "hooks/useTeams";
import useUsers from "hooks/useUsers";
import { ObjectPerissionSWRKeys } from "services/ContentServer/Audit/services/ObjectPermissionService";
import { ObjectPermissions, PermissionValues } from "services/ContentServer/Audit/serviceTypes/ObjectPermissions";
import { User, RoleDict, UserRowData, UserTypes } from "services/ContentServer/Identity";
// import { ENDPOINTS, UsersSWRKeys } from "services/ContentServer/Identity/constants";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { UserInvite } from "services/ContentServer/Identity/serviceTypes/UserInvite";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { formatLastLogin } from "utils/DateUtils";
import { StableSort, GetComparatorFcn, SortDirection, matchSorter } from "utils/SortRowsUtils";
// TODO: FIX STABLE SORT TYPE T[] => T[]
const SITE_LINE_MAX_LENGTH = 35;

const SKELETON_ROWS = 8;

enum UserTabs {
  ActiveTab,
  DeactivatedTab,
  InviteTab,
}

const UsersPage = ({ setSelected }: { setSelected: (value: SettingTab) => void }) => {
  const theme = useTheme();
  const app = useApp();
  const { contentServer } = useContext(RequestContext);
  const { currentUser, roles, rolesLoaded, featureAccess } = useUsers();
  const { getTeamList, loadingTeams, allTeams } = useTeams();
  const { data: sitesDict } = useGetSitesDictQuery();
  const { dispatch: dispatchSnackbarState } = useSnackbar();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [order, setOrder] = useState<SortDirection>();
  const [orderBy, setOrderBy] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [activeUserPageParam, setActiveUserPageParam] = useState<UserParam>({});
  const [deactivatedUserPageParam, setDeactivatedUserPageParam] = useState<UserParam>({});
  const [totalActiveUsers, setTotalActiveUsers] = useState<number>(0);
  const [totalDeactivatedUsers, setTotalDeactivatedUsers] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [expandedSites, setExpandedSites] = useState<Map<string, boolean>>(new Map());
  const [expandedRoles, setExpandedRoles] = useState<Map<string, boolean>>(new Map());
  const [expandedTeams, setExpandedTeams] = useState<Map<string, boolean>>(new Map());
  const [deactivating, setDeactivating] = useState(false);
  const [queryInput, setQueryInput] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [siteFilters, setSiteFilters] = useState<string[]>([]);
  const [teamFilters, setTeamFilters] = useState<string[]>([]);
  const [roleFilters, setRoleFilters] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openUserLog, setOpenUserLog] = useState<boolean>(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const teamsFilterRef = useRef<HTMLHRElement | null>(null);
  const sitesFilterRef = useRef<HTMLHRElement | null>(null);
  const rolesFilterRef = useRef<HTMLHRElement | null>(null);
  const [resendId, setResendId] = useState("");
  const [openCsvLog, setOpenCsvLog] = useState(false);
  const { history } = useRouter();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));
  const matchesLG = useARMediaQuery(theme.breakpoints.down("lg"));
  const { data: objPerms, error: objPermsError } = useSWR([ObjectPerissionSWRKeys.OBJECT_PERMISSIONS], () =>
    contentServer.objectPermissionService.list([["content_type", "site"]])
  );
  const [updateUser] = useUpdateUserMutation();

  const headCells: { id: keyof UserRowData; label: string }[] = useMemo(() => {
    return app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
      ? [
          { id: "User", label: "User" },
          { id: "UserTeam", label: "Teams", disabled: true },
          { id: "UserRole", label: "Roles", disabled: true },
          { id: "LastLogin", label: "Last Login" },
          { id: "ContextMenu", label: "" },
        ]
      : [
          { id: "User", label: "User" },
          { id: "Site", label: "Sites", disabled: true },
          { id: "UserRole", label: "Roles", disabled: true },
          { id: "LastLogin", label: "Last Login" },
          { id: "ContextMenu", label: "" },
        ];
  }, [app]);

  const cellWidths = useMemo(() => {
    return app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
      ? new Map<string, string>([
          ["User", "21%"],
          ["Team", "27.78%"],
          ["Role", "27.78%"],
          ["LastLogin", "20%"],
          ["ContextMenu", "3.42%"],
        ])
      : new Map<string, string>([
          ["User", "21%"],
          ["Site", "27.78%"],
          ["Role", "27.78%"],
          ["LastLogin", "20%"],
          ["ContextMenu", "3.42%"],
        ]);
  }, [app]);

  useEffect(() => {
    requestAnimationFrame(() => setSelected(SettingTab.USERS));
  }, [setSelected]);

  const [getUsersByPage, { isFetching: fetchingUsers, error }] = useLazyGetUsersByPageQuery();

  const { data: invites } = useSWR([UserInviteSWRKeys.USER_INVITES], () => contentServer.userInviteService.list());

  const [tableSize, setTableSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { ref: tableRef } = useRefResizeObserver(setTableSize);

  const getMoreUsersByPage = useCallback(
    (param: UserParam) => {
      if (param && param.page && param.page > 0) {
        getUsersByPage(param)
          .unwrap()
          .then((response) => {
            // get total counts on first request
            if (param.page === 1) {
              getUsersByPage({ ...param, page: 1, isActive: false })
                .unwrap()
                .then((deactiveResp) => {
                  setTotalActiveUsers(response.count);
                  setTotalDeactivatedUsers(deactiveResp.count);

                  setActiveUserPageParam((cur) => ({ ...cur, page: response.nextpage }));
                  setDeactivatedUserPageParam({ ...param, page: deactiveResp.nextpage, isActive: false });
                  setUsers(deactiveResp.users);

                  setLoaded(true);
                });
            } else {
              if (response.param.isActive) {
                setActiveUserPageParam((cur) => ({ ...cur, page: response.nextpage }));
              } else {
                setDeactivatedUserPageParam((cur) => ({ ...cur, page: response.nextpage }));
              }
              setUsers(response.users);
            }
          });
      }
    },
    [getUsersByPage]
  );

  useEffect(() => {
    //run when sort/filter & intial
    const pageParam: UserParam = { page: 1, isActive: true };
    if (order && (orderBy === "User" || orderBy === "LastLogin")) {
      pageParam.order = order;

      if (orderBy === "User") {
        pageParam.orderBy = "userprofile__display_name";
      } else {
        pageParam.orderBy = "last_login";
      }
    }
    if (searchQuery.length > 0) {
      pageParam.name = searchQuery;
    }
    if (siteFilters.length > 0) {
      pageParam.sites = siteFilters;
    }
    if (teamFilters.length > 0) {
      pageParam.teams = teamFilters;
    }
    if (roleFilters.length > 0) {
      pageParam.roles = roleFilters;
    }

    setActiveUserPageParam(pageParam);
    getMoreUsersByPage(pageParam);
  }, [getMoreUsersByPage, setActiveUserPageParam, order, orderBy, siteFilters, teamFilters, roleFilters, searchQuery]);

  const handleRequestSort = useCallback(
    (event: any, property: string) => {
      setOrder(order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
      setOrderBy(property);
    },
    [order]
  );

  const roleAccessRead = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ROLES].read;
  }, [featureAccess]);

  const teamAccessRead = useMemo(() => {
    return featureAccess[PERMISSION_NAME.TEAM].read;
  }, [featureAccess]);

  const userAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER];
  }, [featureAccess]);

  const adminAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ADMIN].read;
  }, [featureAccess]);

  const idListToNames = (ids: string[], objects: any[]) => {
    const names = ids.map((id) => objects.find((obj) => obj.id === id)?.name);
    return names.length > 1 ? names.filter((name) => name !== undefined).join(", ") : names;
  };

  const renderSkeletonRow = useCallback(() => {
    const cells = headCells.map((headCell, idx) => (
      <ARRowCell
        id={`${headCell}-${idx}-loading`}
        key={idx}
        index={idx}
        alwaysShow={headCell.id === "ContextMenu" || headCell.id === "LastLogin"}
        align="left"
        style={{
          width: cellWidths.get(headCell.id),
          height: "20px",
          border: 0,
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingLeft: 0,
        }}
      >
        <Skeleton key={idx} sx={{ borderRadius: "2px", width: "100%", height: "30px" }} />
      </ARRowCell>
    ));
    return cells;
  }, [cellWidths, headCells]);

  const getUserRoles = useCallback((user: User, roles: RoleDict) => {
    const userRoles = {} as RoleDict;
    for (const role of user.roles) {
      if (roles && roles[role]) {
        userRoles[role] = roles[role];
      }
    }
    return userRoles;
  }, []);

  const getRoleDict = useCallback((roles: RoleDict) => {
    const displayRoles = Object.entries(roles).map(([_, value]) => {
      return { id: value.id, name: value.name };
    });
    return {
      ids: Object.values(displayRoles).map((role) => role.id),
      names: Object.values(displayRoles)
        .map((role) => role.name)
        .join(", "),
    };
  }, []);

  const getSiteDict = useCallback(
    (filteredObjPerms: ObjectPermissions[]) => {
      if (sitesDict) {
        const userSites = Object.values(sitesDict)
          .filter((site) => filteredObjPerms?.find((perm) => site.id === perm.objectId))
          .map((site) => {
            return { id: site.id, names: site.name };
          });

        const siteDict = {
          ids: Object.values(userSites).map((site) => site.id),
          names: Object.values(userSites)
            .map((site) => site.names)
            .join(", "),
        };
        return siteDict;
      }
      return { ids: [], names: "" };
    },
    [sitesDict]
  );

  const changeUserActiveState = useCallback(
    async (user: User) => {
      try {
        const newUser = { ...user, isActive: !user.isActive };
        updateUser({ id: user.id, user: newUser });
        dispatchSnackbarState({
          type: SnackbarActionType.OPEN,
          message: newUser.isActive ? "Successfully activated user" : "Successfully deactivated user",
        });
      } catch (error) {
        console.error(error);
        dispatchSnackbarState({
          type: SnackbarActionType.OPEN,
          message: !user.isActive ? "Error activating user" : "Error deactivating user",
        });
      }
      setDeactivating(false);
      setSelectedUserId("");
    },
    [updateUser, dispatchSnackbarState]
  );

  const deleteInvite = useCallback(
    async (inviteId: string) => {
      try {
        mutate(
          [UserInviteSWRKeys.USER_INVITES],
          (prevInvs: UserInvite[] | undefined) => (prevInvs ? [...prevInvs].filter((inv) => inv.id !== inviteId) : []),
          false
        );
        await contentServer.userInviteService.delete(inviteId);
      } catch (err) {
        await mutate([UserInviteSWRKeys.USER_INVITES]);
        console.error();
      }
    },
    [contentServer.userInviteService]
  );

  const userMenuOptions = useCallback(
    (userId: string, userType: UserTypes) => {
      const options: MenuOption[] = [];
      if (userType !== UserTypes.Pending) {
        if (userAccess.update) {
          options.push({
            name: "Edit User",
            onClick: () => {
              const profileRoute = SettingRoutesDict.get(SettingTab.PROFILE);
              if (profileRoute) {
                history.push(`${profileRoute.path}?userId=${userId}`);
              }
            },
            icon: <EditOutlined />,
          });
        }
        if (
          currentUser?.id !== userId &&
          ((userType == UserTypes.Active && userAccess.delete) || (userType == UserTypes.Deactivated && userAccess.add))
        ) {
          options.push({
            name: userType === UserTypes.Active ? "Deactivate User" : "Activate User",
            onClick: () => {
              setSelectedUserId(userId);
              setAccountDialogOpen(true);
            },
            icon: <CopyPageIcon />,
          });
        }
      } else {
        if (userAccess.delete) {
          options.push({
            name: "Delete Invite",
            onClick: () => {
              deleteInvite(userId);
            },
            icon: <DeleteOutline />,
          });
        }

        if (userAccess.add) {
          options.push({
            name: "Resend Invite",
            onClick: () => {
              setResendId(userId);
            },
            icon: <ForwardToInboxSharp />,
          });
        }
      }
      return options;
    },
    [userAccess.update, userAccess.delete, userAccess.add, currentUser?.id, history, deleteInvite]
  );

  const allUsers = useMemo(() => {
    const activeUsers: UserRowData[] = [];
    const deactivatedUsers: UserRowData[] = [];

    users.forEach((user: User) => {
      const userRoles = getUserRoles(user, roles);
      const options = userMenuOptions(user.id, user.isActive ? UserTypes.Active : UserTypes.Deactivated);
      const userInfo = {
        id: user.id,
        User: user.name,
        UserRole: roleAccessRead
          ? !rolesLoaded
            ? { ids: [], names: "Loading..." }
            : getRoleDict(userRoles)
          : { ids: [], names: "You do not have permission to view." },
        UserTeam: teamAccessRead
          ? loadingTeams
            ? { ids: [], names: "Loading..." }
            : getTeamList(user.userProfileId) || {
                ids: [],
                names: "No team assigned.",
              }
          : { ids: [], names: "You do not have permission to view." },
        Site: objPerms
          ? getSiteDict(
              objPerms.filter(
                (obj) =>
                  obj.contentType === "site" &&
                  obj.username === user.id &&
                  obj.permissions?.view === PermissionValues.hasPermission
              )
            )
          : objPermsError
          ? { ids: [], names: "Error fetching info" }
          : { ids: [], names: "Loading ..." },
        LastLogin: !error
          ? user.lastLogin !== "Invalid Date"
            ? formatLastLogin(user.lastLogin)
            : "- - -"
          : "Error fetching info",
        ContextMenu: options && options.length > 0 ? <ContextButton items={options} /> : <></>,
        userType: user.isActive ? UserTypes.Active : UserTypes.Deactivated,
      };

      if (user.isActive) {
        activeUsers.push(userInfo);
      } else {
        deactivatedUsers.push(userInfo);
      }
    });

    return { active: activeUsers, deactivated: deactivatedUsers };
  }, [
    users,
    roleAccessRead,
    teamAccessRead,
    getRoleDict,
    objPerms,
    getSiteDict,
    objPermsError,
    error,
    userMenuOptions,
    roles,
    getUserRoles,
    getTeamList,
    loadingTeams,
    rolesLoaded,
  ]);

  const inviteEntries = useMemo(() => {
    if (invites) {
      return invites.map((inv) => {
        const options = userMenuOptions(inv.id ? inv.id : "", UserTypes.Pending);
        return {
          id: inv.id ? inv.id : "- - -",
          User: inv.username ? inv.username : "- - -",
          UserRole: { ids: inv.roles, names: inv.roles ? idListToNames(inv.roles, Object.values(roles)) : "---" },
          UserTeam: { ids: inv.teams, names: inv.teams && allTeams ? idListToNames(inv.teams, allTeams) : "---" },
          Site: {
            ids: inv.sites,
            names: inv.sites && sitesDict ? idListToNames(inv.sites, Object.values(sitesDict)) : "---",
          },
          LastLogin: "- - -",
          ContextMenu: options && options.length > 0 ? <ContextButton items={options} /> : <></>,
          userType: UserTypes.Pending,
          createdAt: inv.createdAt ? inv.createdAt : "",
        } as UserRowData;
      });
    } else {
      return [];
    }
  }, [invites, roles, sitesDict, allTeams, userMenuOptions]);

  const applyFilters = useCallback(
    (data: UserRowData[][]) => {
      const currData = [...data];

      if (searchQuery.length > 0 && inviteEntries) {
        currData[UserTabs.InviteTab] = matchSorter(inviteEntries, searchQuery, { keys: ["User"] });
      }
      if (siteFilters.length > 0 && !siteFilters.includes(ALL_RESULTS)) {
        currData[UserTabs.InviteTab] = currData[UserTabs.InviteTab].filter(
          (row) => intersection(row.Site.ids, siteFilters).length === siteFilters.length
        );
      }
      if (teamFilters.length > 0 && !teamFilters.includes(ALL_RESULTS)) {
        currData[UserTabs.InviteTab] = currData[UserTabs.InviteTab].filter(
          (row) => intersection(row.UserTeam.ids, teamFilters).length === teamFilters.length
        );
      }
      if (roleFilters.length > 0 && !roleFilters.includes(ALL_RESULTS)) {
        currData[UserTabs.InviteTab] = currData[UserTabs.InviteTab].filter(
          (row) => intersection(row.UserRole.ids, roleFilters).length === roleFilters.length
        );
      }
      return currData;
    },
    [teamFilters, roleFilters, siteFilters, searchQuery, inviteEntries]
  );

  const tableData = useMemo(() => {
    return applyFilters([allUsers.active, allUsers.deactivated, inviteEntries ? inviteEntries : []]);
  }, [allUsers.active, allUsers.deactivated, applyFilters, inviteEntries]);

  const noResultsMsg = useCallback(() => {
    let userType = "active";
    if (tab === UserTabs.DeactivatedTab) {
      userType = "deactivated";
    } else if (tab === UserTabs.InviteTab) {
      userType = "pending";
    }
    return `No ${userType} users${searchQuery ? " match this search." : "."}`;
  }, [searchQuery, tab]);

  useEffect(() => {
    if (tab === UserTabs.ActiveTab) {
      setExpandedSites(new Map(tableData[UserTabs.ActiveTab].map((row) => [row.id, false])));
      setExpandedRoles(new Map(tableData[UserTabs.ActiveTab].map((row) => [row.id, false])));
      setExpandedTeams(new Map(tableData[UserTabs.ActiveTab].map((row) => [row.id, false])));
    } else if (tab === UserTabs.DeactivatedTab) {
      setExpandedSites(new Map(tableData[UserTabs.DeactivatedTab].map((row) => [row.id, false])));
      setExpandedRoles(new Map(tableData[UserTabs.DeactivatedTab].map((row) => [row.id, false])));
      setExpandedTeams(new Map(tableData[UserTabs.DeactivatedTab].map((row) => [row.id, false])));
    }
  }, [tableData, tab]);

  const isExpanded = useCallback(
    (id: string, headKey: string) => {
      const state = headKey === "Site" ? expandedSites : headKey === "Role" ? expandedRoles : expandedTeams;
      const expanded = state.get(id);
      return expanded === undefined ? false : expanded;
    },
    [expandedRoles, expandedSites, expandedTeams]
  );

  const updateExpandedRows = useCallback(
    (id: string, expanded: boolean | ((prev: boolean) => boolean), headKey: string) => {
      const state = headKey === "Site" ? expandedSites : headKey === "Role" ? expandedRoles : expandedTeams;
      const setState = headKey === "Site" ? setExpandedSites : headKey === "Role" ? setExpandedRoles : setExpandedTeams;

      typeof expanded === "boolean"
        ? setState(new Map(state.set(id, expanded)))
        : setState(new Map(state.set(id, expanded(isExpanded(id, headKey)))));
    },
    [expandedRoles, expandedSites, expandedTeams, isExpanded]
  );

  const userExpandedValues = useCallback(
    (id: string, values: string, headKey: string) => {
      const state = headKey === "Site" ? expandedSites : headKey === "Role" ? expandedRoles : expandedTeams;
      const expandedUser = state.get(id);
      if (values && values.length > SITE_LINE_MAX_LENGTH) {
        return expandedUser ? values + " " : values.slice(0, SITE_LINE_MAX_LENGTH) + "... ";
      }
      return values;
    },
    [expandedRoles, expandedSites, expandedTeams]
  );

  const tableRows = useMemo(() => {
    if (tab === UserTabs.InviteTab) {
      return StableSort(tableData[tab], GetComparatorFcn(order, orderBy, orderBy === "LastLogin"));
    }
    return tableData[tab];
  }, [order, orderBy, tableData, tab]);

  const exportCsvButton = useMemo(() => {
    <ExportUsersDialog open={openCsvLog} setOpen={setOpenCsvLog} />;
    return (
      <MenuItem
        onClick={() => {
          setOpenCsvLog(true);
          setOpen(false);
        }}
        disabled={!adminAccess}
      >
        <SourceOutlined sx={{ color: theme.palette.primary.main }} style={{ paddingRight: "5px" }} />
        {"Export Users to CSV"}
      </MenuItem>
    );
  }, [adminAccess, openCsvLog, theme]);

  const signInLogsButton = useMemo(() => {
    <UserLogDialog open={openUserLog} setOpen={setOpenUserLog} />;
    return (
      <MenuItem
        onClick={() => {
          setOpenUserLog(true);
          setOpen(false);
        }}
        disabled={!adminAccess}
      >
        <SourceOutlined sx={{ color: theme.palette.primary.main }} style={{ paddingRight: "5px" }} />
        {"Retrieve User Sign-in Logs"}
      </MenuItem>
    );
  }, [adminAccess, openUserLog, theme]);

  const isUserActive = useMemo(() => {
    const user = users ? users.find((u) => u.id === selectedUserId) : undefined;
    return user ? user.isActive : false;
  }, [selectedUserId, users]);

  const handleSearch = useCallback(() => {
    setSearchQuery(queryInput);
  }, [queryInput]);

  const updateQuery = useCallback(
    (queryString: string, exitSearch = false) => {
      if (exitSearch) {
        setSearchQuery("");
      }
      setQueryInput(queryString);
    },
    [setQueryInput]
  );

  const tableRow = useCallback(
    (rowIdx, row) => {
      return (
        <>
          {headCells.map((headCell, idx) => {
            const widthPercent = cellWidths.get(headCell.id);
            return (
              <ARRowCell
                id={`cell-col-${idx}-${rowIdx}`}
                key={idx}
                index={headCell.id === "UserTeam" || headCell.id === "UserRole" ? 2 : idx}
                alwaysShow={headCell.id === "ContextMenu" || headCell.id === "LastLogin"}
                align="left"
                padding="normal"
                style={{
                  width: widthPercent,
                  maxWidth: (tableSize.width - (matchesSM ? 6 : matchesMD ? 8 : 13) * 16) * 0.21,
                  border: 0,
                  paddingLeft: idx === 0 ? "16px" : "0px",
                  paddingRight: headCell.id === "ContextMenu" ? 0 : "",
                }}
                onClick={() => {
                  if (headCell.id !== "ContextMenu") {
                    const profileRoute = SettingRoutesDict.get(SettingTab.PROFILE);
                    if (profileRoute) {
                      history.push(
                        `${profileRoute.path}?userId=${row.id}${
                          row.userType === UserTypes.Pending ? "&isPending=true" : ""
                        }`
                      );
                    }
                  }
                }}
              >
                <Box sx={{ display: "flex", width: "100%", pr: 1 }}>
                  <Box sx={{ maxHeight: "24px", maxWidth: "100%" }}>
                    <EllipsisTooltip variant="body1">
                      {headCell.id === "Site" || headCell.id === "UserRole" || headCell.id === "UserTeam"
                        ? userExpandedValues(row.id, row[headCell.id]["names"], headCell.id)
                        : row[headCell.id]}
                    </EllipsisTooltip>
                  </Box>
                  {(headCell.id === "Site" || headCell.id === "UserRole" || headCell.id === "UserTeam") &&
                    row[headCell.id]["names"].length > SITE_LINE_MAX_LENGTH && (
                      <Box>
                        <ExpandDropdown
                          expanded={isExpanded(row.id, headCell.id)}
                          setExpanded={(value: boolean | ((prev: boolean) => boolean)) =>
                            updateExpandedRows(row.id, value, headCell.id)
                          }
                          style={{ fontSize: "14px", color: theme.palette.primary.main }}
                        />
                      </Box>
                    )}
                </Box>
              </ARRowCell>
            );
          })}
        </>
      );
    },
    [
      matchesSM,
      matchesMD,
      headCells,
      history,
      isExpanded,
      updateExpandedRows,
      userExpandedValues,
      cellWidths,
      tableSize.width,
      theme,
    ]
  );

  return (
    <>
      <LoadingDialog
        processing={deactivating || fetchingUsers}
        msg={deactivating ? "Updating User State..." : "Loading Users..."}
      />
      <AccountDialog
        useOpen={{ state: accountDialogOpen, setState: setAccountDialogOpen }}
        dialogTitle={isUserActive ? "Deactivate Account" : "Activate Account"}
        dialogContent={`Are you sure you would like to ${isUserActive ? "deactivate" : "activate"} this account? ${
          isUserActive ? "This user will no longer have access to AptixAR." : ""
        }`}
        buttonText={isUserActive ? "Deactivate" : "Activate"}
        onConfirm={async () => {
          if (selectedUserId && users) {
            setDeactivating(true);
            const user = users.find((u) => u.id === selectedUserId);
            if (user) {
              await changeUserActiveState(user);
            }
          }
        }}
        onClose={() => {
          setSelectedUserId("");
        }}
      />
      <ResendInviteDialog resendId={resendId} setResendId={setResendId} fromUserList={true} />
      <UserLogDialog open={openUserLog} setOpen={setOpenUserLog} />
      <ExportUsersDialog open={openCsvLog} setOpen={setOpenCsvLog} />
      {inviteDialogOpen && (
        <UserInviteDialog open={inviteDialogOpen} setOpen={setInviteDialogOpen} allTeams={allTeams} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "flex-start" }}>
        <Box
          sx={{
            display: "flex",
            height: "auto",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            py: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
          }}
        >
          <Box sx={{ height: "auto", width: "100%" }}>
            <Typography variant="h1">Users</Typography>
          </Box>
          <Box>
            {app.isFeatureEnabled(TenantFeatureEnum.SigninLogs) && adminAccess && (
              <MoreMenu open={open} setOpen={setOpen} horizontal>
                {signInLogsButton}
                {exportCsvButton}
              </MoreMenu>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            py: 3,
            gap: 3,
            width: "100%",
            height: "auto",
          }}
        >
          <PaginationSearchBar
            placeholderText={"Search users..."}
            inSearchMode={Boolean(searchQuery)}
            query={queryInput}
            updateQuery={updateQuery}
            handleSearch={handleSearch}
          />
          {userAccess.add && (
            <Button
              variant={"outlined"}
              startIcon={<Add />}
              onClick={() => {
                setInviteDialogOpen(true);
              }}
              style={{ maxHeight: "36px", minWidth: "150px" }}
            >
              Invite Users
            </Button>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "auto",
            pb: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
          }}
        >
          <Box sx={{ borderBottom: matchesLG ? "none" : "solid 1px #e1e2e4" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: matchesLG ? "column" : "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  borderBottom: matchesLG ? "solid 1px #e1e2e4" : "none",
                }}
              >
                <HeaderTabs
                  tab={tab}
                  setTab={setTab}
                  headers={[
                    `Active Users (${totalActiveUsers})`,
                    `Deactivated Users (${totalDeactivatedUsers})`,
                    `Pending Invites (${tableData[UserTabs.InviteTab].length})`,
                  ]}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 2,
                  pt: matchesLG ? 2 : 1.2,
                  height: "100%",
                }}
              >
                {app.isFeatureEnabled(TenantFeatureEnum.AssetV2) ? (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      ref={teamsFilterRef}
                      style={{
                        maxWidth: "160px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Teams: " +
                        (teamFilters.length > 0 && allTeams
                          ? idListToNames(teamFilters, Object.values(allTeams))
                          : "No Filter")}
                    </Typography>
                    <DropdownFilter
                      options={
                        allTeams
                          ? allTeams.map((team) => {
                              return { id: team.id, label: team.name } as SearchOption;
                            })
                          : [{ id: "", label: "" }]
                      }
                      placeholderText={"Search Teams..."}
                      refElement={teamsFilterRef}
                      currentValue={teamFilters}
                      setCurrentValue={setTeamFilters}
                      selectAllText={"All Teams"}
                      clearAllOption={true}
                    />
                  </Box>
                ) : (
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      ref={sitesFilterRef}
                      style={{
                        maxWidth: "160px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Sites: " +
                        (siteFilters.length > 0 && sitesDict
                          ? idListToNames(siteFilters, Object.values(sitesDict))
                          : "No Filter")}
                    </Typography>
                    <DropdownFilter
                      options={
                        sitesDict
                          ? Object.values(sitesDict).map((site) => {
                              return { id: site.id, label: site.name } as SearchOption;
                            })
                          : [{ id: "", label: "" }]
                      }
                      placeholderText={"Search sites..."}
                      refElement={sitesFilterRef}
                      currentValue={siteFilters}
                      setCurrentValue={setSiteFilters}
                      selectAllText={"All Sites"}
                      clearAllOption={true}
                    />
                  </Box>
                )}
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography
                    ref={rolesFilterRef}
                    variant="body2"
                    style={{
                      maxWidth: "160px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"Roles: " +
                      (roleFilters.length > 0 ? idListToNames(roleFilters, Object.values(roles)) : "No Filter")}
                  </Typography>
                  <DropdownFilter
                    options={Object.values(roles).map((role) => {
                      return { id: role.id, label: role.name } as SearchOption;
                    })}
                    placeholderText={"Search roles..."}
                    currentValue={roleFilters}
                    setCurrentValue={setRoleFilters}
                    refElement={rolesFilterRef}
                    selectAll={false}
                    clearAllOption={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: "100%", width: "100%" }}>
          {error || (loaded && tableData[tab].length == 0 && loaded) ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "0",
                gap: "40px",
                px: matchesSM ? 6 : matchesMD ? 8 : 13,
              }}
            >
              {error ? (
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  Error fetching users.
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  {noResultsMsg()}
                </Typography>
              )}
            </Box>
          ) : (
            <TableContainer
              ref={tableRef}
              sx={{
                height: "100%",
                width: "100%",
                "&::-webkit-scrollbar": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#a2a4a6",
                  height: "12vh",
                  maxHeight: "12vh",
                  minHeight: "12vh",
                  width: "8px",
                  borderRadius: "100px",
                },
                table: {
                  px: matchesSM ? 6 : matchesMD ? 8 : 13,
                },
              }}
            >
              {tableData[tab].length > 0 ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <TableVirtuoso
                    data={tableRows}
                    style={{ height: "100%", width: "100%", tableLayout: "fixed" }}
                    itemContent={(index, rowData) => tableRow(index, rowData)}
                    fixedHeaderContent={() => (
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        cellWidths={cellWidths}
                        width={tableSize.width}
                      />
                    )}
                    atBottomStateChange={() => {
                      if (tab === UserTabs.ActiveTab) {
                        getMoreUsersByPage(activeUserPageParam);
                      } else if (tab === UserTabs.DeactivatedTab) {
                        getMoreUsersByPage(deactivatedUserPageParam);
                      }
                    }}
                  />
                </div>
              ) : (
                <Table size="small" style={{ maxWidth: "100%", width: "100%", height: "100%" }} stickyHeader>
                  <TableBody style={{ width: "100%", height: "100%" }}>
                    {!loaded &&
                      Array(SKELETON_ROWS)
                        .fill(1)
                        .map((ele, idx) => {
                          return <TableRow key={idx}>{renderSkeletonRow()}</TableRow>;
                        })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UsersPage;
